/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out fresh */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./assets/Jqx_styles/jqx.iotify.css";
@import "~jqwidgets-ng/jqwidgets/styles/jqx.energyblue.css";
@import "~jqwidgets-ng/jqwidgets/styles/jqx.shinyblack.css";
@import "./assets/Jqx_styles/jqx.treeiot.css";

.card-content-md {
    padding: 0px;
}
.ng-disabled{
    cursor: not-allowed;
}

ion-col {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    // padding-left: 0px;
}

.side-filter-custom-class {
    height: 90% !important;
}

.ion-input-type-number {
    border: 1px solid #e5e5e5;
}

.close-modal {
    display: flex;
    align-items: flex-end;
}

.level-card-padding {
    padding: 0 4px;
  }

.loading-gif {
// height: 1rem;
// width: 1.5rem;
margin: 5px 10px 0px 0px;
}

.spin {
  --spinner-color: rgb(109, 109, 209);
 
}
//!iotify themes===================
.custom-iot-primary{
    background-image: linear-gradient(180deg, #1E3B70,#29539B);
    color:white

}

//!iotify themes===================

.my-custom-class{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 70%;
        height:38%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  
    // --border-radius: 20px;
    // --max-height:fit-content;
    // --max-width: 70%;
   
    // --border-style: dotted;
    // --border-color: #428cff;
    // border-width: 5px;
    

//   border: 1px solid rgb(14 30 37 / 32%);"
   
}
// .my-custom-class.stack-modal {
//     --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
//     --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
//   }
.my-custom2-class{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 70%;
        height:38%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.remotecommandmodel{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 40%;
        height:50%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.simDeviceModal{
    &::part(content){
        background-color:#fdfbfbf5;
        width:450px;
        height:50%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.insideModal{
    &::part(content){
        background-color:#fdfbfbf5;
        width:50%;
        top:20%;
        height:55%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.siteInsideModal{
   
&::part(content){
        background-color:#fdfbfbf5;
        width:30%;
        height:30%;
        border-radius: 12px;
        top:60%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }    
   
}

.change-label-caption {
    ::ng-deep .jqx-gauge-label {
        font-size: 9px;
    }
    ::ng-deep .jqx-gauge-caption {
    font-size: 9px;
    }
}
.settingsPage{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 100%;
        height:100%;
        //border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.sitePageModal_2{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 80%;
        height:75%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.sitePageModal_1{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 50%;
        height:55%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.sitePageMap{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 50%;
        height:70%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.selectionPop{
    &::part(content){
        background-color:#fdfbfbf5;
        position:absolute;
        width: 50px;
        height:100px;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
ion-modal[class*="action-modal"] {
    &::part(content){
        background-color:#fdfbfbf5;
        position: absolute !important;
        top: 7% !important;
        width: 85% !important;
        height:95% !important;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 20px;
    top: 4px;
    left: 27px;
}

.slider {
    border: 0;
    padding: 0;
    display: block;
    // margin: 12px 5px;
    min-height: 11px;
    margin: 0 ! important;
    // position: absolute;
    cursor: pointer;
    top: 0;
    left: -2px;
    right: 0;
    bottom: 0;
    background-color: red;
    // -webkit-transition: .4s;
    transition: .4s;
    position: absolute;
}
.slider.round {
    border-radius: 34px ! important;
}

input:checked+.slider {
    margin: 0px ! important;
    background-color: #4caf50;
}
// we going to set class attribute global level
[class*="viewportHeightSt"] {
    height:90vh;
  }
//   app-dashboard{

//   }
ion-icon:hover{
cursor:pointer;
}
ion-item[class*="inputrapper"]{
    &::part(native){
        --min-height:40px;
       
    }
    box-shadow: rgb(50 50 105 / 15%) 0px 2px 5px 0px, rgb(119 217 181 / 50%) 0px 1px 1px 0px;//prety
    --background-hover: #cfdde9;  
    margin: 3px;
    font-size: 0.9rem;
}

//!dashboard grid line break removing using this classname
.dashboardcells {
    border-width: 0px 0px 1px 0px !important;
        // border-style: solid;
        // border-width: 0px 0px 1px 0px;
        // margin-right: 1px;
        // margin-bottom: 1px;
        // border-color: transparent;
        // background: #fff;
        // white-space: nowrap;
        // font-weight: normal;
       // font-size: 0.9rem;
        // overflow: hidden;
        // position: absolute !important;
        // height: 100%;
        // outline: none;
}

.section-div{
    border-right: 1px solid lightsteelblue;
    padding: 2px 2px 0px 0px;
}
//! 
.com-filtercss{
    background: #2c7c79;
    /* padding: 5px; */
    border-radius: 3px;
    color: white;
    height: 35px;
  }
 .iotify-font-bold{
    font-size: 0.9rem;
    font-weight: 500;
 }
 .iotify-font{
    font-size: 0.9rem;
 }
 div[data-role="treeview"] span{
    border-radius: 50% !important;
 }
 //this line for ion toggle at remote commands...
 ion-toggle{
    --background:rgba(237, 10, 10, 0.523);
    --background-checked:red;
 }
 // this rempte commands...
 //grid page seritiy column 
 .tooltipimg:hover .tooltiptext2{
    visibility: visible;
  }
 //severity 

 //ion search border 
 ion-searchbar{
    --border-radius: 25px !important;
 }
 //login page item size alt
 ion-item[class*="login-input"]{
    &::part(native){
        --min-height:38px !important;
       
    }
    // box-shadow: rgb(50 50 105 / 15%) 0px 2px 5px 0px, rgb(119 217 181 / 50%) 0px 1px 1px 0px;//prety
    // --background-hover: #cfdde9;  
    // margin: 3px;
    // font-size: 0.9rem;
};
// theme set
.themeTemp{
    // background: linear-gradient(#3ab3ae 7%, #06e5dc 80%, #2adbd4d6 0%) !important;
    // background: linear-gradient(#09203F 7%, #558db8 80%, #4f90cb 0%) !important;
    background-color: #2c7c79;
}

//jqx widgets cjhangess only
.jqx-grid-pager-input-iotify{
   text-align: center !important;
   padding: 0 !important;
}
//jqx widgets cjhangess only

//iotify theme applications
.headerTheme-iotify{
    font-size:0.8rem;
    font-weight: 500;
    color:white;
}
//iotify theme applications
//searchbar custom styling
.dashboardSearch .searchbar-input{
    line-height: 22px;
}
.dashboardSearch .searchbar-search-icon{
    left: 12px;
    top: 8px;
    width: 21px;
    height: 21px;
}

//searchbar custom styling
//! jqx grid theme overirde
.jqx-widget-header-iotify{
   
        background: linear-gradient(180deg, #2c7c79, #40b0ac,#329592)!important;
        color:white !important;
    
    }
    .jqx-fill-state-hover-iotify{
        font-size: 13px !important; 
         background-color: #00000012 !important;
    }
    .jqx-fill-state-pressed-iotify{
        font-size: 14px !important;
    background-color: #0000003b !important;
    font-weight: 400 !important;
    color: black !important;
    }
        .jqx-item-iotify input[type="button"]{
            background:#4CAF50;
        }

        .remotegridtriggercol{
            height: 82%;
            width: 92%;
            display: flex;
            justify-content: space-around;
            color:whitesmoke;
            align-items: center;
            background: #4CAF50;
            /* margin-right: 0px; */
            margin-left: 6px;
            margin-top: 4px;
            /* border: 1px solid gray; */
            cursor: pointer;
            border-radius: 5px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        }

//--jqx-grid-hovered-row-background-color-iotify
//var(--jqx-grid-hovered-row-text-color-iotify) !important
//! jqx grid theme overirde    #4CAF50