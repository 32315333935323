:root { 
   --jqx-accent-color-iotify: rgba(44, 124, 121, 1);
   --jqx-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-header-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-header-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-border-color-iotify: rgba(231, 231, 231, 1);
   --jqx-border-radius-iotify: 2px;
   --jqx-font-family-iotify: "Roboto", "Helvetice Neue", "Helvetica", "Arial", sans-serif;
   --jqx-font-size-iotify: 13px ;
   --jqx-hovered-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-hovered-state-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-hovered-state-content-text-color-iotify: rgba(51, 51, 51, 1);
   --jqx-hovered-state-content-background-color-iotify: rgba(221, 241, 240, 1);
   --jqx-focused-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-focused-state-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-active-state-text-color-iotify: rgba(44, 124, 121, 1);
   --jqx-active-state-background-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-arrow-up-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
   --jqx-arrow-down-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
   --jqx-arrow-up-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
   --jqx-arrow-down-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
   --jqx-arrow-up-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
   --jqx-arrow-down-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
   --jqx-accordion-item-title-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-accordion-item-title-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-accordion-item-title-border-color-iotify: rgba(231, 231, 231, 1);
   --jqx-accordion-item-title-hovered-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-accordion-item-title-hovered-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-accordion-item-title-hovered-border-color-iotify: rgba(224, 224, 224, 1);
   --jqx-accordion-item-title-selected-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-accordion-item-title-selected-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-accordion-item-title-selected-border-color-iotify: rgba(224, 224, 224, 1);
   --jqx-accordion-item-title-focused-border-color-iotify: rgba(44, 124, 121, 1);
   --jqx-accordion-item-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-accordion-item-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-accordion-item-focused-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-accordion-item-focused-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-accordion-item-focused-border-color-iotify: rgba(44, 124, 121, 1);
   --jqx-accordion-arrow-up-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
   --jqx-accordion-arrow-down-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
   --jqx-accordion-arrow-up-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
   --jqx-accordion-arrow-down-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
   --jqx-accordion-arrow-down-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
   --jqx-button-default-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-default-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-button-default-hovered-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-default-hovered-state-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-button-default-focused-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-default-focused-state-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-button-default-active-state-text-color-iotify: rgba(44, 124, 121, 1);
   --jqx-button-default-active-state-background-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-button-primary-text-color-iotify: rgba(28, 168, 221, 1);
   --jqx-button-primary-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-primary-hovered-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-primary-hovered-state-background-color-iotify: rgba(28, 168, 221, 1);
   --jqx-button-primary-focused-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-primary-focused-state-background-color-iotify: rgba(28, 168, 221, 1);
   --jqx-button-primary-active-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-primary-active-state-background-color-iotify: rgba(28, 168, 221, 1);
   --jqx-button-success-text-color-iotify: rgba(92, 184, 92, 1);
   --jqx-button-success-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-success-hovered-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-success-hovered-state-background-color-iotify: rgba(92, 184, 92, 1);
   --jqx-button-success-focused-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-success-focused-state-background-color-iotify: rgba(92, 184, 92, 1);
   --jqx-button-success-active-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-success-active-state-background-color-iotify: rgba(92, 184, 92, 1);
   --jqx-button-warning-text-color-iotify: rgba(240, 173, 78, 1);
   --jqx-button-warning-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-warning-hovered-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-warning-hovered-state-background-color-iotify: rgba(240, 173, 78, 1);
   --jqx-button-warning-focused-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-warning-focused-state-background-color-iotify: rgba(240, 173, 78, 1);
   --jqx-button-warning-active-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-warning-active-state-background-color-iotify: rgba(240, 173, 78, 1);
   --jqx-button-danger-text-color-iotify: rgba(217, 83, 79, 1);
   --jqx-button-danger-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-danger-hovered-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-danger-hovered-state-background-color-iotify: rgba(217, 83, 79, 1);
   --jqx-button-danger-focused-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-danger-focused-state-background-color-iotify: rgba(217, 83, 79, 1);
   --jqx-button-danger-active-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-danger-active-state-background-color-iotify: rgba(217, 83, 79, 1);
   --jqx-button-info-text-color-iotify: rgba(91, 192, 222, 1);
   --jqx-button-info-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-info-hovered-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-info-hovered-state-background-color-iotify: rgba(91, 192, 222, 1);
   --jqx-button-info-focused-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-info-focused-state-background-color-iotify: rgba(91, 192, 222, 1);
   --jqx-button-info-active-state-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-button-info-active-state-background-color-iotify: rgba(91, 192, 222, 1);
   --jqx-editors-text-editors-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-editors-text-editors-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-editors-text-editors-border-color-iotify: rgba(231, 231, 231, 1);
   --jqx-editors-text-editors-border-radius-iotify: 2px;
   --jqx-editors-text-editors-focused-border-color-iotify: rgba(44, 124, 121, 1);
   --jqx-editors-datеtimeinput-calendar-icon-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-calendar.png');
   --jqx-editors-datеtimeinput-calendar-icon-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-calendar.png');
   --jqx-editors-combobox-multi-item-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-editors-combobox-multi-item-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-editors-calendar-header-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-editors-calendar-header-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-editors-calendar-arrow-left-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
                        --jqx-editors-calendar-arrow-right-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
   --jqx-editors-calendar-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-editors-calendar-today-cell-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-editors-calendar-today-cell-background-color-iotify: rgba(255, 255, 219, 1);
   --jqx-editors-calendar-today-cell-border-color-iotify: rgba(253, 192, 102, 1);
   --jqx-editors-calendar-current-month-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-editors-calendar-other-month-text-color-iotify: rgba(137, 137, 137, 1);
   --jqx-editors-calendar-hovered-cell-text-color-iotify: rgba(51, 51, 51, 1);
   --jqx-editors-calendar-hovered-cell-background-color-iotify: rgba(221, 241, 240, 1);
   --jqx-editors-calendar-selected-cell-text-color-iotify: rgba(44, 124, 121, 1);
   --jqx-editors-calendar-selected-cell-background-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-editors-switch-thumb-color-iotify: rgba(44, 124, 121, 1);
   --jqx-editors-switch-on-label-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-editors-switch-on-label-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-editors-switch-off-label-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-editors-switch-off-label-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-editors-file-uploader-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-editors-file-uploader-filename-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-grid-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-grid-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-grid-header-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-grid-header-text-weight-iotify: normal;
   --jqx-grid-header-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-grid-row-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-grid-row-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-grid-row-alternation-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-grid-row-alternation-background-color-iotify: rgba(249, 249, 249, 1);
   --jqx-grid-hovered-row-text-color-iotify: rgba(51, 51, 51, 1);
   --jqx-grid-hovered-row-background-color-iotify: rgba(221, 241, 240, 1);
   --jqx-grid-selected-row-text-color-iotify: rgba(44, 124, 121, 1);
   --jqx-grid-selected-row-background-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-grid-selected-row-border-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-grid-group-row-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-grid-group-row-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-grid-editor-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-grid-editor-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-grid-pinned-cells-background-color-iotify: rgba(229, 229, 229, 1);
   --jqx-grid-arrow-down-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
   --jqx-grid-arrow-right-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
   --jqx-grid-arrow-left-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
   --jqx-grid-close-button-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/close_white.png');
   --jqx-grid-arrow-down-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
   --jqx-grid-arrow-right-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
   --jqx-grid-arrow-left-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
   --jqx-grid-arrow-down-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
   --jqx-grid-arrow-right-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
   --jqx-grid-arrow-left-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
   --jqx-grid-header-arrow-down-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
   --jqx-grid-header-arrow-up-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
   --jqx-grid-menu-button-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-menu-small-white.png');
   --jqx-grid-groups-arrow-down-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
   --jqx-grid-groups-arrow-right-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
   --jqx-grid-groups-arrow-left-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
   --jqx-list-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-list-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-list-header-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-list-header-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-list-hovered-item-state-text-color-iotify: rgba(51, 51, 51, 1);
   --jqx-list-hovered-item-state-background-color-iotify: rgba(221, 241, 240, 1);
   --jqx-list-selected-item-state-text-color-iotify: rgba(44, 124, 121, 1);
   --jqx-list-selected-item-state-background-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-list-arrow-left-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
   --jqx-list-arrow-right-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
   --jqx-list-arrow-left-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
   --jqx-list-arrow-right-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
   --jqx-menu-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-menu-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-menu-dropdown-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-menu-dropdown-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-menu-arrow-up-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
   --jqx-menu-arrow-down-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
   --jqx-menu-arrow-left-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
   --jqx-menu-arrow-right-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
   --jqx-menu-hovered-item-color-iotify: rgba(51, 51, 51, 1);
   --jqx-menu-hovered-item-background-color-iotify: rgba(221, 241, 240, 1);
   --jqx-menu-selected-item-color-iotify: rgba(44, 124, 121, 1);
   --jqx-menu-selected-item-background-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-menu-selected-item-border-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-menu-arrow-up-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
   --jqx-menu-arrow-down-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
   --jqx-menu-arrow-left-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
   --jqx-menu-arrow-right-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
   --jqx-navbar-item-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-navbar-item-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-navbar-hovered-item-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-navbar-hovered-item-background-color-iotify: rgba(44, 124, 121, 0.9);
   --jqx-navbar-selected-item-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-navbar-selected-item-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-overlays-content-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-overlays-content-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-overlays-shader-background-color-iotify: rgba(0, 0, 0, 0.6);
   --jqx-overlays-popup-header-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-overlays-popup-header-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-overlays-tooltip-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-overlays-tooltip-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-overlays-toast-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-overlays-toast-info-background-color-iotify: rgba(91, 192, 222, 1);
   --jqx-overlays-toast-warning-background-color-iotify: rgba(240, 173, 78, 1);
   --jqx-overlays-toast-error-background-color-iotify: rgba(217, 83, 79, 1);
   --jqx-overlays-toast-success-background-color-iotify: rgba(92, 184, 92, 1);
   --jqx-pivotgrid-header-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-pivotgrid-header-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-pivotgrid-cell-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-pivotgrid-cell-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-pivotgrid-selected-cell-text-color-iotify: rgba(44, 124, 121, 1);
   --jqx-pivotgrid-selected-cell-background-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-pivotgrid-arrow-up-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
   --jqx-pivotgrid-arrow-down-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
   --jqx-pivotgrid-menu-button-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-menu-small-white.png');
   --jqx-progressbar-bar-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-progressbar-selected-range-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-progressbar-label-color-iotify: rgba(85, 85, 85, 1);
   --jqx-scheduler-header-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-scheduler-header-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-scheduler-header-border-color-iotify: rgba(231, 231, 231, 1);
   --jqx-scheduler-header-buttons-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-scheduler-header-buttons-background-color-iotify: rgba(85, 139, 47, 1);
   --jqx-scheduler-header-buttons-hovered-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-scheduler-header-buttons-hovered-background-color-iotify: rgba(85, 139, 47, 1);
   --jqx-scheduler-header-buttons-selected-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-scheduler-header-buttons-selected-background-color-iotify: rgba(85, 139, 47, 1);
   --jqx-scheduler-border-color-iotify: rgba(231, 231, 231, 1);
   --jqx-scheduler-not-work-time-cells-color-iotify: rgba(238, 238, 238, 1);
   --jqx-scheduler-hovered-cell-background-color-iotify: rgba(221, 241, 240, 1);
   --jqx-scheduler-hovered-cell-border-color-iotify: rgba(221, 241, 240, 1);
   --jqx-scheduler-selected-cell-background-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-scheduler-selected-cell-border-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-scheduler-weeks-arrow-right-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
   --jqx-scheduler-weeks-arrow-left-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
   --jqx-scheduler-weeks-arrow-right-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
   --jqx-scheduler-weeks-arrow-left-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
   --jqx-scheduler-calendar-icon-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-calendar.png');
   -jqx-light-scheduler-calendar-icon-selected-color: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-calendar.png');
   --jqx-scrollbar-scrollbar-background-color-iotify: rgba(245, 245, 245, 1);
   --jqx-scrollbar-scrollbar-thumb-background-color-iotify: rgba(245, 245, 245, 1);
   --jqx-scrollbar-scrollbar-thumb-hovered-background-color-iotify: rgba(230, 230, 230, 1);
   --jqx-scrollbar-scrollbar-thumb-active-background-color-iotify: rgba(217, 217, 217, 1);
   --jqx-scrollbar-scrollbar-arrow-background-color-iotify: rgba(245, 245, 245, 1);
   --jqx-scrollbar-arrow-up-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
   --jqx-scrollbar-arrow-down-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
   --jqx-scrollbar-arrow-left-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
   --jqx-scrollbar-arrow-right-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
   --jqx-scrollbar-scrollbar-arrow-hovered-background-color-iotify: rgba(232, 232, 232, 1);
   --jqx-scrollbar-arrow-up-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
   --jqx-scrollbar-arrow-down-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
   --jqx-scrollbar-arrow-left-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
   --jqx-scrollbar-arrow-right-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
   --jqx-scrollbar-scrollbar-arrow-active-background-color-iotify: rgba(217, 217, 217, 1);
   --jqx-scrollbar-arrow-up-active-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
   --jqx-scrollbar-arrow-down-active-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
   --jqx-scrollbar-arrow-left-active-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
   --jqx-scrollbar-arrow-right-active-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
   --jqx-scrollview-indicator-background-color-iotify: rgba(250, 250, 250, 1);
   --jqx-scrollview-selected-indicator-background-color-iotify: rgba(250, 250, 250, 1);
   --jqx-slider-slider-bar-background-color-iotify: rgba(240, 240, 240, 1);
   --jqx-slider-selected-slider-background-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-slider-slider-thumb-color-iotify: rgba(44, 124, 121, 1);
   --jqx-slider-focused-border-color-iotify: rgba(44, 124, 121, 1);
   --jqx-slider-tooltip-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-slider-tooltip-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-slider-tooltip-border-color-iotify: rgba(44, 124, 121, 1);
   --jqx-slider-arrows-background-color-iotify: rgba(255, 255, 255, 0);
   --jqx-slider-arrows-hovered-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-slider-arrows-selected-background-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-slider-arrow-left-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
   --jqx-slider-arrow-right-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
   --jqx-slider-arrow-left-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
   --jqx-slider-arrow-right-hovered-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
   --jqx-slider-arrow-left-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
   --jqx-slider-arrow-right-selected-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
   --jqx-tabs-header-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-tabs-tab-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-tabs-hovered-tab-text-color-iotify: rgba(255, 255, 255, 1);
   --jqx-tabs-hovered-tab-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-tabs-selected-tab-text-color-iotify: rgba(44, 124, 121, 1);
   --jqx-tabs-selected-tab-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-tabs-selected-tab-bottom-border-color-iotify: rgba(44, 124, 121, 1);
   --jqx-tabs-border-color-iotify: rgba(231, 231, 231, 1);
   --jqx-tabs-content-text-color-iotify: rgba(85, 85, 85, 1);
   --jqx-tabs-content-background-color-iotify: rgba(255, 255, 255, 1);
   --jqx-tabs-arrow-left-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
   --jqx-tabs-arrow-right-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
   --jqx-toolbar-background-color-iotify: rgba(44, 124, 121, 1);
   --jqx-treeview-hovered-tree-item-color-iotify: rgba(51, 51, 51, 1);
   --jqx-treeview-hovered-tree-item-background-color-iotify: rgba(221, 241, 240, 1);
   --jqx-treeview-selected-tree-item-color-iotify: rgba(44, 124, 121, 1);
   --jqx-treeview-selected-tree-item-background-color-iotify: rgba(44, 124, 121, 0.2);
   --jqx-treeview-arrow-expanded-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
   --jqx-treeview-arrow-collapsed-color-iotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
}

/*Rounded Corners*/
/*top-left rounded Corners*/
.jqx-rc-tl-iotify {
    -moz-border-radius-topleft: var(--jqx-border-radius-iotify);
    -webkit-border-top-left-radius: var(--jqx-border-radius-iotify);
    border-top-left-radius: var(--jqx-border-radius-iotify);
}
/*top-right rounded Corners*/
.jqx-rc-tr-iotify {
    -moz-border-radius-topright: var(--jqx-border-radius-iotify);
    -webkit-border-top-right-radius: var(--jqx-border-radius-iotify);
    border-top-right-radius: var(--jqx-border-radius-iotify);
}
/*bottom-left rounded Corners*/
.jqx-rc-bl-iotify {
    -moz-border-radius-bottomleft: var(--jqx-border-radius-iotify);
    -webkit-border-bottom-left-radius: var(--jqx-border-radius-iotify);
    border-bottom-left-radius: var(--jqx-border-radius-iotify);
}
/*bottom-right rounded Corners*/
.jqx-rc-br-iotify {
    -moz-border-radius-bottomright: var(--jqx-border-radius-iotify);
    -webkit-border-bottom-right-radius: var(--jqx-border-radius-iotify);
    border-bottom-right-radius: var(--jqx-border-radius-iotify);
}
/*top rounded Corners*/
.jqx-rc-t-iotify {
    -moz-border-radius-topleft: var(--jqx-border-radius-iotify);
    -webkit-border-top-left-radius: var(--jqx-border-radius-iotify);
    border-top-left-radius: var(--jqx-border-radius-iotify);
    -moz-border-radius-topright: var(--jqx-border-radius-iotify);
    -webkit-border-top-right-radius: var(--jqx-border-radius-iotify);
    border-top-right-radius: var(--jqx-border-radius-iotify);
}
/*bottom rounded Corners*/
.jqx-rc-b-iotify {
    -moz-border-radius-bottomleft: var(--jqx-border-radius-iotify);
    -webkit-border-bottom-left-radius: var(--jqx-border-radius-iotify);
    border-bottom-left-radius: var(--jqx-border-radius-iotify);
    -moz-border-radius-bottomright: var(--jqx-border-radius-iotify);
    -webkit-border-bottom-right-radius: var(--jqx-border-radius-iotify);
    border-bottom-right-radius: var(--jqx-border-radius-iotify);
}
/*right rounded Corners*/
.jqx-rc-r-iotify {
    -moz-border-radius-topright: var(--jqx-border-radius-iotify);
    -webkit-border-top-right-radius: var(--jqx-border-radius-iotify);
    border-top-right-radius: var(--jqx-border-radius-iotify);
    -moz-border-radius-bottomright: var(--jqx-border-radius-iotify);
    -webkit-border-bottom-right-radius: var(--jqx-border-radius-iotify);
    border-bottom-right-radius: var(--jqx-border-radius-iotify);
}
/*left rounded Corners*/
.jqx-rc-l-iotify {
    -moz-border-radius-topleft: var(--jqx-border-radius-iotify);
    -webkit-border-top-left-radius: var(--jqx-border-radius-iotify);
    border-top-left-radius: var(--jqx-border-radius-iotify);
    -moz-border-radius-bottomleft: var(--jqx-border-radius-iotify);
    -webkit-border-bottom-left-radius: var(--jqx-border-radius-iotify);
    border-bottom-left-radius: var(--jqx-border-radius-iotify);
}
/*all rounded Corners*/
.jqx-rc-all-iotify {
    -moz-border-radius: var(--jqx-border-radius-iotify);
    -webkit-border-radius: var(--jqx-border-radius-iotify);
    border-radius: var(--jqx-border-radius-iotify);
}

.jqx-widget-iotify, .jqx-widget-header-iotify, .jqx-fill-state-normal-iotify,
.jqx-widget-content-iotify, .s, .jqx-fill-state-pressed-iotify {
    font-family: var(--jqx-font-family-iotify);
    font-size: var(--jqx-font-size-iotify);
}

.jqx-widget-iotify {
    font-family: var(--jqx-font-family-iotify);
    font-size: var(--jqx-font-size-iotify);
    color: var(--jqx-text-color-iotify);
}

.jqx-widget-content-iotify {
    font-family: var(--jqx-font-family-iotify);
    font-size: var(--jqx-font-size-iotify);
    color: var(--jqx-text-color-iotify);
    background-color: var(--jqx-background-color-iotify);
    border-color: var(--jqx-border-color-iotify);
}

.jqx-widget-header-iotify {
    background-color: var(--jqx-header-background-color-iotify);
    border-color: var(--jqx-border-color-iotify);
    color: var(--jqx-header-text-color-iotify);
    font-weight: 500;
    *zoom: 1;
    font-family: var(--jqx-font-family-iotify);
    background: var(--jqx-header-background-color-iotify);;
    color: var(--jqx-header-text-color-iotify);
    font-size: var(--jqx-font-size-iotify);
}


.jqx-fill-state-normal-iotify {
    border-color: var(--jqx-accent-color-iotify);
    *zoom: 1;
    background: var(--jqx-background-color-iotify);
}
.jqx-widget-iotify input::selection, input.jqx-input-widget-iotify::selection, .jqx-widget-content-iotify input::selection {
    background: var(--jqx-accent-color-iotify);
    color: var(--jqx-background-color-iotify);
}
.jqx-toolbar-iotify{
    background-color: var(--jqx-toolbar-background-color-iotify);
     border-color: var(--jqx-border-color-iotify);
}

.jqx-button-iotify, jqx-button-iotify.jqx-fill-state-normal-iotify {
    color: var(--jqx-button-default-text-color-iotify);
    background: var(--jqx-button-default-background-color-iotify);
    border-color: var(--jqx-button-default-background-color-iotify);
    *zoom: 1;
    outline: none;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: var(--jqx-border-radius-iotify);
}
.jqx-button-iotify button, jqx-button-iotify input {
    background: transparent;
    color: inherit;
    border:none;
    outline: none;
}


.jqx-slider-button-iotify {
    padding:3px;
    background: transparent;
    border:transparent;
}
    .jqx-button-iotify.float {
        border-radius: 100%;
        min-height: 48px;
        min-width: 48px;
        width: 48px;
        height: 48px;
        max-height: 48px;
        max-width:48px;
    }

    .jqx-button-iotify.outlined {
        background: transparent;
        color: var(--jqx-accent-color-iotify);
        border-width: 2px;
    }

    .jqx-button-iotify.flat {
        background: transparent;
        color: var(--jqx-accent-color-iotify);
        border: none;
    }



.jqx-fill-state-hover-iotify, .jqx-fill-state-focus-iotify {
   font-size: 12px !important;
}

.jqx-expander-header.jqx-fill-state-hover-iotify,
.jqx-expander-header.jqx-fill-state-normal-iotify
{
     background-color: var(--jqx-accordion-item-title-background-color-iotify) !important;
     border-color: var(--jqx-accordion-item-title-border-color-iotify);
     color: var(--jqx-accordion-item-title-text-color-iotify) !important;
}

.jqx-expander-header.jqx-fill-state-pressed-iotify {
   background-color: var(--jqx-accordion-item-title-selected-background-color-iotify) !important;
   border-color: var(--jqx-accordion-item-title-selected-border-color-iotify);
   color: var(--jqx-accordion-item-title-selected-text-color-iotify) !important;
}

.jqx-expander-header.jqx-fill-state-hover-iotify {
   background-color: var(--jqx-accordion-item-title-hovered-background-color-iotify) !important;
   color: var(--jqx-accordion-item-title-hovered-text-color-iotify) !important;
   border-color: var(--jqx-accordion-item-title-hovered-border-color-iotify) !important;
}
.jqx-expander-header.jqx-fill-state-focus-iotify {
   border-color: var(--jqx-accordion-item-title-focused-border-color-iotify) !important;
}

.jqx-expander-content-iotify {
    background-color: var(--jqx-accordion-item-background-color-iotify);
    color: var(--jqx-accordion-item-text-color-iotify);
}

.jqx-expander-content.jqx-fill-state-focus-iotify {
   border-color: var(--jqx-accordion-item-focused-border-color-iotify) !important;
   background-color: var(--jqx-accordion-item-focused-background-color-iotify);
   color: var(--jqx-accordion-item-focused-text-color-iotify);
}

.jqx-expander-header-iotify {
    padding:10px;
}
.jqx-button-iotify.jqx-fill-state-hover {
    opacity: 0.9;
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    *zoom: 1;
    color: var(--jqx-button-default-hovered-state-text-color-iotify);
}

    .jqx-button-iotify.jqx-fill-state-hover.outlined,
    .jqx-button-iotify.jqx-fill-state-hover.flat {
        color: var(--jqx-accent-color-iotify);
        box-shadow: none;
    }

.jqx-button-iotify.jqx-fill-state-pressed {
    cursor: pointer;
    background: var(--jqx-accent-color-iotify);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

    .jqx-button-iotify.jqx-fill-state-pressed.float {
        box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    }

    .jqx-slider-button-iotify.jqx-fill-state-pressed-iotify,
    .jqx-button-iotify.jqx-fill-state-pressed.outlined,
    .jqx-button-iotify.jqx-fill-state-pressed.flat {
        background: rgba(179,229,252,0.15);
        box-shadow: none;
        color: var(--jqx-accent-color-iotify); 
    }

.jqx-button-iotify.jqx-fill-state-focus {
    background: var(--jqx-button-default-focused-state-background-color-iotify);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
  .jqx-slider-button-iotify.jqx-fill-state-focus-iotify {
      background: transparent;
      border-color: transparent;
      box-shadow:none;
  }
  
    .jqx-button-iotify.jqx-fill-state-focus.outlined,
    .jqx-button-iotify.jqx-fill-state-focus.flat {
        box-shadow: none;
        background: rgba(99,0,238,0.15);
        color: #55882F;
    }

.jqx-dropdownlist-state-normal-iotify, .jqx-dropdownlist-state-hover-iotify, .jqx-dropdownlist-state-selected-iotify,
.jqx-scrollbar-button-state-hover-iotify, .jqx-scrollbar-button-state-normal-iotify, .jqx-scrollbar-button-state-pressed-iotify,
.jqx-scrollbar-thumb-state-normal-horizontal-iotify, .jqx-scrollbar-thumb-state-hover-horizontal-iotify, .jqx-scrollbar-thumb-state-pressed-horizontal-iotify,
.jqx-scrollbar-thumb-state-normal-iotify, .jqx-scrollbar-thumb-state-pressed-iotify, .jqx-tree-item-hover-iotify, .jqx-tree-item-selected-iotify,
.jqx-tree-item-iotify, .jqx-menu-item-iotify, .jqx-menu-item-hover-iotify, .jqx-menu-item-selected-iotify, .jqx-menu-item-top-iotify, .jqx-menu-item-top-hover-iotify,
.jqx-menu-item-top-selected-iotify, .jqx-slider-button-iotify, .jqx-slider-slider-iotify {
    -webkit-transition: background-color 100ms linear;
    -moz-transition: background-color 100ms linear;
    -o-transition: background-color 100ms linear;
    -ms-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
}


.jqx-primary-iotify.jqx-input-label-iotify {
   color: var(--jqx-button-primary-text-color-iotify) !important; 
}
.jqx-primary-iotify.jqx-input-bar-iotify:before {
   background: var(--jqx-button-primary-text-color-iotify) !important;
}
.jqx-success-iotify.jqx-input-label-iotify {
   color: var(--jqx-button-success-text-color-iotify) !important;
}
.jqx-success-iotify.jqx-input-bar-iotify:before {
   background: var(--jqx-button-success-text-color-iotify) !important;
}
.jqx-inverse-iotify.jqx-input-label-iotify {
   color: #666 !important;
}
.jqx-inverse-iotify.jqx-input-bar-iotify:before {
   background: #666 !important;
}
.jqx-danger-iotify.jqx-input-label-iotify {
   color: var(--jqx-button-danger-text-color-iotify) !important;
}
.jqx-danger-iotify.jqx-input-bar-iotify:before {
   background: var(--jqx-button-danger-text-color-iotify) !important;
}
.jqx-warning-iotify.jqx-input-label-iotify {
   color: var(--jqx-button-warning-text-color-iotify) !important;
}
.jqx-warning-iotify.jqx-input-bar-iotify:before {
   background: var(--jqx-button-warning-text-color-iotify) !important;
}
.jqx-info-iotify.jqx-input-label-iotify {
   color: var(--jqx-button-info-text-color-iotify) !important;
}
.jqx-info-iotify.jqx-input-bar-iotify:before {
   background: var(--jqx-button-info-text-color-iotify) !important;
}

.jqx-slider-tooltip-iotify.jqx-primary-slider, .jqx-slider-tooltip-iotify.jqx-primary-slider .jqx-fill-state-normal-iotify {
    border-color: var(--jqx-button-primary-text-color-iotify);
    background: var(--jqx-button-primary-text-color-iotify);
}
.jqx-slider-tooltip-iotify.jqx-success-slider, .jqx-slider-tooltip-iotify.jqx-success-slider .jqx-fill-state-normal-iotify {
    border-color: var(--jqx-button-success-text-color-iotify);
    background: var(--jqx-button-success-text-color-iotify);
}
.jqx-slider-tooltip-iotify.jqx-inverse-slider, .jqx-slider-tooltip-iotify.jqx-inverse-slider .jqx-fill-state-normal-iotify {
    border-color: #666;
    background: #666;
}
.jqx-slider-tooltip-iotify.jqx-danger-slider, .jqx-slider-tooltip-iotify.jqx-danger-slider .jqx-fill-state-normal-iotify {
    border-color: var(--jqx-button-danger-text-color-iotify);
    background: var(--jqx-button-danger-text-color-iotify);
}
.jqx-slider-tooltip-iotify.jqx-warning-slider, .jqx-slider-tooltip-iotify.jqx-warning-slider .jqx-fill-state-normal-iotify {
    border-color: var(--jqx-button-warning-text-color-iotify);
    background: var(--jqx-button-warning-text-color-iotify);
}
.jqx-slider-tooltip-iotify.jqx-info-slider, .jqx-slider-tooltip-iotify.jqx-info-slider .jqx-fill-state-normal-iotify {
    border-color: var(--jqx-button-info-text-color-iotify);;
    background: var(--jqx-button-info-text-color-iotify);;
}

.jqx-primary-iotify {
    color: var(--jqx-button-primary-text-color-iotify) !important;
    background: var(--jqx-button-primary-background-color-iotify) !important;
    border-color: var(--jqx-button-primary-text-color-iotify) !important;
    text-shadow: none !important;
}

    .jqx-primary-iotify.jqx-dropdownlist-state-normal-iotify,
    .jqx-primary-iotify.jqx-slider-button-iotify,
    .jqx-primary-iotify.jqx-slider-slider-iotify,
    .jqx-primary-iotify.jqx-combobox-arrow-normal-iotify,
    .jqx-primary-iotify.jqx-combobox-arrow-hover-iotify,
    .jqx-primary-iotify.jqx-action-button-iotify,
    .jqx-primary-iotify:hover,    
    .jqx-primary-iotify:active,
    .jqx-primary-iotify.active,
    .jqx-primary-iotify.disabled,
    .jqx-primary-iotify[disabled] {
        color: var(--jqx-button-primary-hovered-state-text-color-iotify) !important;
        background: var(--jqx-button-primary-hovered-state-background-color-iotify) !important;
        border-color: var(--jqx-button-primary-hovered-state-background-color-iotify) !important;
        text-shadow: none !important;
    }

    .jqx-primary-iotify:focus {
        color: var(--jqx-button-primary-focused-state-text-color-iotify) !important;
        background: var(--jqx-button-primary-focused-state-background-color-iotify) !important;
        border-color: var(--jqx-button-primary-focused-state-background-color-iotify) !important;
    }

    .jqx-fill-state-pressed-iotify.jqx-primary-iotify,
    .jqx-primary-iotify:active,
    .jqx-primary-iotify.active {
        color:var(--jqx-button-primary-active-state-text-color-iotify) !important;
        background-color: var(--jqx-button-primary-active-state-background-color-iotify) !important;
        border-color: var(--jqx-button-primary-active-state-background-color-iotify) !important;
        text-shadow: none !important;
    }

.jqx-success-iotify {
    color: var(--jqx-button-success-text-color-iotify) !important;
    background: var(--jqx-button-success-background-color-iotify) !important;
    border-color: var(--jqx-button-success-text-color-iotify) !important;
    text-shadow: none !important;
}

    .jqx-success-iotify.jqx-dropdownlist-state-normal-iotify,
    .jqx-success-iotify.jqx-slider-button-iotify,
    .jqx-success-iotify.jqx-slider-slider-iotify,
    .jqx-success-iotify.jqx-combobox-arrow-normal-iotify,
    .jqx-success-iotify.jqx-combobox-arrow-hover-iotify,
    .jqx-success-iotify.jqx-action-button-iotify,
    .jqx-success-iotify:hover
    .jqx-success-iotify:active,
    .jqx-success-iotify.active,
    .jqx-success-iotify.disabled,
    .jqx-success-iotify[disabled] {
        color: var(--jqx-button-success-hovered-state-text-color-iotify) !important;
        background: var(--jqx-button-success-hovered-state-background-color-iotify) !important;
        border-color: var(--jqx-button-success-hovered-state-background-color-iotify) !important;
        text-shadow: none !important;
    }

    .jqx-success-iotify:focus {
        color: var(--jqx-button-success-focused-state-text-color-iotify) !important;
        background: var(--jqx-button-success-focused-state-background-color-iotify) !important;
        border-color: var(--jqx-button-success-focused-state-background-color-iotify) !important;
    }

    .jqx-fill-state-pressed-iotify.jqx-success-iotify,
    .jqx-success-iotify:active,
    .jqx-success-iotify.active {
        color:var(--jqx-button-success-active-state-text-color-iotify) !important;
        background-color: var(--jqx-button-success-active-state-background-color-iotify) !important;
        border-color: var(--jqx-button-success-active-state-background-color-iotify) !important;
        text-shadow: none !important;
    }

.jqx-inverse-iotify {
    text-shadow: none !important;
    color: #666 !important;
    background: #fff !important;
    border-color: #cccccc !important;
}

    .jqx-inverse-iotify.jqx-dropdownlist-state-normal-iotify,
    .jqx-inverse-iotify.jqx-slider-button-iotify,
    .jqx-inverse-iotify.jqx-slider-slider-iotify,
    .jqx-inverse-iotify.jqx-combobox-arrow-hover-iotify,
    .jqx-inverse-iotify.jqx-combobox-arrow-normal-iotify,
    .jqx-inverse-iotify.jqx-action-button-iotify,
    .jqx-inverse-iotify:hover,
    .jqx-inverse-iotify:focus,
    .jqx-inverse-iotify:active,
    .jqx-inverse-iotify.active,
    .jqx-inverse-iotify.disabled,
    .jqx-inverse-iotify[disabled] {
        text-shadow: none !important;
        color: #666 !important;
        background: #cccccc !important;
        border-color: #cccccc !important;
    }

    .jqx-fill-state-pressed-iotify.jqx-inverse-iotify,
    .jqx-inverse-iotify:active,
    .jqx-inverse-iotify.active {
        text-shadow: none !important;
        color: #666 !important;
        background: #cccccc !important;
        border-color: #cccccc !important;
    }


.jqx-danger-iotify {
    text-shadow: none !important;
    color: var(--jqx-button-danger-text-color-iotify) !important;
    background: var(--jqx-button-danger-background-color-iotify) !important;
    border-color: var(--jqx-button-danger-text-color-iotify) !important;
}

    .jqx-danger-iotify.jqx-dropdownlist-state-normal-iotify,
    .jqx-danger-iotify.jqx-slider-button-iotify,
    .jqx-danger-iotify.jqx-slider-slider-iotify,
    .jqx-danger-iotify.jqx-combobox-arrow-hover-iotify,
    .jqx-danger-iotify.jqx-combobox-arrow-normal-iotify,
    .jqx-danger-iotify.jqx-action-button-iotify,
    .jqx-danger-iotify:hover,
    .jqx-danger-iotify:active,
    .jqx-danger-iotify.active,
    .jqx-danger-iotify.disabled,
    .jqx-danger-iotify[disabled] {
        text-shadow: none !important;
        color: var(--jqx-button-danger-hovered-state-text-color-iotify) !important;
        background: var(--jqx-button-danger-hovered-state-background-color-iotify) !important;
        border-color: var(--jqx-button-danger-hovered-state-background-color-iotify) !important;
    }

    .jqx-danger-iotify:focus {
        color: var(--jqx-button-danger-focused-state-text-color-iotify) !important;
        background: var(--jqx-button-danger-focused-state-background-color-iotify) !important;
        border-color: var(--jqx-button-danger-focused-state-background-color-iotify) !important;
    }

    .jqx-fill-state-pressed-iotify.jqx-danger-iotify,
    .jqx-danger-iotify:active,
    .jqx-danger-iotify.active {
        text-shadow: none !important;
        color:var(--jqx-button-danger-active-state-text-color-iotify) !important;
        background-color: var(--jqx-button-danger-active-state-background-color-iotify) !important;
        border-color: var(--jqx-button-danger-active-state-background-color-iotify) !important;
    }

.jqx-validator-error-label-iotify {
    color: #d9534f !important;
}

.jqx-warning-iotify {
    text-shadow: none !important;
    color: var(--jqx-button-warning-text-color-iotify) !important;
    background: var(--jqx-button-warning-background-color-iotify) !important;
    border-color: var(--jqx-button-warning-text-color-iotify) !important;
}

    .jqx-warning-iotify.jqx-dropdownlist-state-normal-iotify,
    .jqx-warning-iotify.jqx-slider-button-iotify,
    .jqx-warning-iotify.jqx-slider-slider-iotify,
    .jqx-warning-iotify.jqx-combobox-arrow-hover-iotify,
    .jqx-warning-iotify.jqx-combobox-arrow-normal-iotify,
    .jqx-warning-iotify.jqx-action-button-iotify,
    .jqx-warning-iotify:hover
    .jqx-warning-iotify:active,
    .jqx-warning-iotify.active,
    .jqx-warning-iotify.disabled,
    .jqx-warning-iotify[disabled] {
        text-shadow: none !important;
        color: var(--jqx-button-warning-hovered-state-text-color-iotify) !important;
        background: var(--jqx-button-warning-hovered-state-background-color-iotify) !important;
        border-color: var(--jqx-button-warning-hovered-state-background-color-iotify) !important;
    }

    .jqx-warning-iotify:focus {
        color: var(--jqx-button-warning-focused-state-text-color-iotify) !important;
        background: var(--jqx-button-warning-focused-state-background-color-iotify) !important;
        border-color: var(--jqx-button-warning-focused-state-background-color-iotify) !important;
    }

    .jqx-fill-state-pressed-iotify.jqx-warning-iotify,
    .jqx-warning-iotify:active,
    .jqx-warning-iotify.active {
        text-shadow: none !important;
        color:var(--jqx-button-warning-active-state-text-color-iotify) !important;
        background-color: var(--jqx-button-warning-active-state-background-color-iotify) !important;
        border-color: var(--jqx-button-warning-active-state-background-color-iotify) !important;
    }


.jqx-info-iotify {
    text-shadow: none !important;
    color: var(--jqx-button-info-text-color-iotify) !important;
    background: var(--jqx-button-info-background-color-iotify) !important;
    border-color: var(--jqx-button-info-text-color-iotify) !important;
}

    .jqx-info-iotify.jqx-dropdownlist-state-normal-iotify,
    .jqx-info-iotify.jqx-slider-button-iotify,
    .jqx-info-iotify.jqx-slider-slider-iotify,
    .jqx-info-iotify.jqx-combobox-arrow-hover-iotify,
    .jqx-info-iotify.jqx-combobox-arrow-normal-iotify,
    .jqx-info-iotify.jqx-action-button-iotify,
    .jqx-info-iotify:hover,
    .jqx-info-iotify:active,
    .jqx-info-iotify.active,
    .jqx-info-iotify.disabled,
    .jqx-info-iotify[disabled] {
        color: var(--jqx-button-info-hovered-state-text-color-iotify) !important;
        background: var(--jqx-button-info-hovered-state-background-color-iotify) !important;
        border-color: var(--jqx-button-info-hovered-state-background-color-iotify) !important;
        text-shadow: none !important;
    }

    .jqx-info-iotify:focus {
        color: var(--jqx-button-info-focused-state-text-color-iotify) !important;
        background: var(--jqx-button-info-focused-state-background-color-iotify) !important;
        border-color: var(--jqx-button-info-focused-state-background-color-iotify) !important;
    }

    .jqx-fill-state-pressed-iotify.jqx-info-iotify,
    .jqx-info-iotify:active,
    .jqx-info-iotify.active {
        text-shadow: none !important;
        color:var(--jqx-button-info-active-state-text-color-iotify) !important;
        background-color: var(--jqx-button-info-active-state-background-color-iotify) !important;
        border-color: var(--jqx-button-info-active-state-background-color-iotify) !important;
    }

    .jqx-loader-iotify, .jqx-popover-content-iotify {
        color: var(--jqx-overlays-content-text-color-iotify);
        background-color: var(--jqx-overlays-content-background-color-iotify);
    }
    
    .jqx-popover-modal-background-iotify {
        background-color: var(--jqx-overlays-shader-background-color-iotify);
    }
    
    .jqx-popover-title-iotify {
        color: var(--jqx-overlays-popup-header-text-color-iotify) !important;
        background-color: var(--jqx-overlays-popup-header-background-color-iotify) !important;
    }
    
    .jqx-loader-text-iotify {
        color: var(--jqx-overlays-content-text-color-iotify);
    }
    
    .jqx-notification-iotify {
        color: var(--jqx-overlays-toast-text-color-iotify) !important;
    }
    
    .jqx-notification-info.jqx-notification-iotify {
        background-color: var(--jqx-overlays-toast-info-background-color-iotify) !important;
        border-color: var(--jqx-overlays-toast-info-background-color-iotify) !important;
    }  
    
    .jqx-notification-warning.jqx-notification-iotify {
        background-color: var(--jqx-overlays-toast-warning-background-color-iotify) !important;
        border-color: var(--jqx-overlays-toast-warning-background-color-iotify) !important;
    }  
    
    .jqx-notification-error.jqx-notification-iotify {
        background-color: var(--jqx-overlays-toast-error-background-color-iotify) !important;
        border-color: var(--jqx-overlays-toast-error-background-color-iotify) !important;
    }  
    
    .jqx-notification-success.jqx-notification-iotify {
        background-color: var(--jqx-overlays-toast-success-background-color-iotify) !important;
        border-color: var(--jqx-overlays-toast-success-background-color-iotify) !important;
    } 

.jqx-fill-state-pressed-iotify {
    background-image: none;
    outline: 0;
}

.jqx-grid-group-column-iotify {
    border-color: transparent;
}
.jqx-grid-column-menubutton-iotify {
    border-width: 0px;
}
.jqx-grid-groups-row-iotify > span {
    padding-left: 4px;
}

.jqx-grid-cell-iotify, .jqx-grid-group-cell-iotify {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: var(--jqx-grid-row-background-color-iotify);
    color: var(--jqx-grid-row-text-color-iotify);
}

.jqx-grid-cell-alt-iotify {
    background-color: var(--jqx-grid-row-alternation-background-color-iotify) !important;
    color: var(--jqx-grid-row-alternation-text-color-iotify) !important;
}

.jqx-grid-pager-top-iotify .jqx-button-iotify,
.jqx-grid-pager-iotify .jqx-button-iotify {
    color: rgba(0,0,0,.54) !important;
    background-color: transparent;
    border-color: transparent;
    position: relative;
    height:30px !important;
    width:30px !important;
    border-radius:50%;
    top: -4px;
}

.jqx-grid-pager-input-iotify  {
    padding:0px !important;
}

.jqx-grid-pager-top-iotify .jqx-button-iotify > div,
.jqx-grid-pager-iotify .jqx-button-iotify > div {
    top: 3px;
    position: relative;
    left: 2px;
}

.jqx-grid-pager-top-iotify .jqx-button-iotify.jqx-fill-state-hover,
.jqx-grid-pager-top-iotify .jqx-button-iotify.jqx-fill-state-pressed,
.jqx-grid-pager-iotify .jqx-button-iotify.jqx-fill-state-hover,
.jqx-grid-pager-iotify .jqx-button-iotify.jqx-fill-state-pressed
{
    color: rgba(0,0,0,.54) !important;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;    
}

.jqx-grid-pager-top-iotify .jqx-grid-pager-number-iotify,
.jqx-grid-pager-iotify .jqx-grid-pager-number-iotify {

    background-color: transparent;
    border-color: transparent;
    color: rgba(0,0,0,.54) !important;
    font-size:12px;
}

.jqx-grid-pager-top-iotify .jqx-grid-pager-number-iotify:hover,
.jqx-grid-pager-iotify .jqx-grid-pager-number-iotify:hover {
    font-size:12px;
}

.jqx-grid-pager-top-iotify .jqx-grid-pager-number-iotify.jqx-fill-state-pressed-iotify ,
.jqx-grid-pager-iotify .jqx-grid-pager-number-iotify.jqx-fill-state-pressed-iotify {
    color: var(--jqx-accent-color-iotify) !important;
    font-weight: bold !important;
}

.jqx-grid-column-menubutton-iotify {
    background-color: transparent;
    border-color: #E0E0E0 !important;
}

.jqx-grid-column-header-iotify, .jqx-grid-groups-header-iotify, .jqx-grid-pager-iotify {
    background-color: var(--jqx-grid-header-background-color-iotify) !important;
    color: var(--jqx-grid-header-text-color-iotify) !important;
    font-weight: var(--jqx-grid-header-text-weight-iotify);
}

.jqx-grid-group-expand-iotify + div, .jqx-grid-group-collapse-iotify + div {
    background-color: var(--jqx-grid-group-row-background-color-iotify);
    color: var(--jqx-grid-group-row-text-color-iotify);
}

.jqx-grid-cell-pinned-iotify {
    background-color: var(--jqx-grid-pinned-cells-background-color-iotify) !important;
}

#groupsheadergrid .jqx-fill-state-normal-iotify {
    background-color: var(--jqx-grid-background-color-iotify) !important;
}

.jqx-cell-iotify {
    font-size: 13px;
}

.jqx-calendar-iotify > div {
    padding: 10px;
    box-sizing: border-box;
}

.jqx-calendar-row-header-iotify, .jqx-calendar-top-left-header-iotify {
    background-color: #f0f0f0;
    border: 0px solid #f2f2f2;
}

.jqx-calendar-title-header-iotify {
    color: var(--jqx-editors-calendar-header-text-color-iotify);
    background-color: var(--jqx-editors-calendar-header-background-color-iotify);
}
.jqx-calendar-column-header-iotify {
    color: var(--jqx-editors-calendar-header-text-color-iotify);
    background-color: var(--jqx-editors-calendar-header-background-color-iotify);
    border-top: 1px solid var(--jqx-editors-calendar-header-background-color-iotify);
    border-bottom: 1px solid var(--jqx-editors-calendar-header-background-color-iotify);
}

.jqx-calendar-month-container-iotify {
    background-color: var(--jqx-editors-calendar-background-color-iotify);
}

.jqx-calendar-cell-month-iotify {
    color: var(--jqx-editors-calendar-current-month-text-color-iotify) !important;
}

.jqx-calendar-cell-othermonth-iotify {
    color: var(--jqx-editors-calendar-other-month-text-color-iotify) !important;
}

.jqx-calendar-cell-today-iotify {
    color: var(--jqx-editors-calendar-today-cell-text-color-iotify) !important;
    background-color: var(--jqx-editors-calendar-today-cell-background-color-iotify);
    border-color: var(--jqx-editors-calendar-today-cell-border-color-iotify);
}

.jqx-calendar-cell-hover-iotify {
    color: var(--jqx-editors-calendar-hovered-cell-text-color-iotify) !important;
    background-color: var(--jqx-editors-calendar-hovered-cell-background-color-iotify);
    border-color: var(--jqx-editors-calendar-hovered-cell-background-color-iotify); 
}
.jqx-calendar-cell-selected-iotify {
    color: var(--jqx-editors-calendar-selected-cell-text-color-iotify) !important;
    background-color: var(--jqx-editors-calendar-selected-cell-background-color-iotify);
    border-color: var(--jqx-editors-calendar-selected-cell-background-color-iotify);
}

.jqx-expander-header-iotify {
    padding-top: 10px;
    padding-bottom: 10px;
}

.jqx-ribbon-header-vertical-iotify, .jqx-widget-header-vertical-iotify {
    background: #fff;
}

.jqx-scrollbar-state-normal-iotify {
    background-color: var(--jqx-scrollbar-scrollbar-background-color-iotify);
    border: 1px solid #f5f5f5;
    border-left-color: #ddd;
}

.jqx-scrollbar-button-state-normal-iotify {
    background-color: var(--jqx-scrollbar-scrollbar-arrow-background-color-iotify);
}

.jqx-scrollbar-button-state-hover-iotify {
    background: var(--jqx-scrollbar-scrollbar-arrow-hovered-background-color-iotify);
}

.jqx-scrollbar-button-state-pressed-iotify {
    background: var(--jqx-scrollbar-scrollbar-arrow-active-background-color-iotify) !important;
    /* border-color: var(--jqx-scrollbar-scrollbar-arrow-active-background-color-iotify); */
}

.jqx-scrollbar-thumb-state-normal-iotify, .jqx-scrollbar-thumb-state-normal-horizontal-iotify {
    background: var(--jqx-scrollbar-scrollbar-thumb-background-color-iotify);
    border-color: #b3b3b3;
}

.jqx-scrollbar-thumb-state-hover-iotify, .jqx-scrollbar-thumb-state-hover-horizontal-iotify {
    background: var(--jqx-scrollbar-scrollbar-thumb-hovered-background-color-iotify);
    border-color: #b3b3b3;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}


/* scrollbar arrow up */
.jqx-scrollbar-button-state-normal-iotify .jqx-icon-arrow-up-iotify {
    background-image: var(--jqx-scrollbar-arrow-up-color-iotify);
}
.jqx-scrollbar-button-state-hover-iotify .jqx-icon-arrow-up-hover-iotify {
    background-image: var(--jqx-scrollbar-arrow-up-hovered-color-iotify);
}
.jqx-scrollbar-button-state-pressed-iotify .jqx-icon-arrow-up-selected-iotify {
    background-image: var(--jqx-scrollbar-arrow-up-active-color-iotify);
}

/* scrollbar arrow down */
.jqx-scrollbar-button-state-normal-iotify .jqx-icon-arrow-down-iotify {
    background-image: var(--jqx-scrollbar-arrow-down-color-iotify);
}
.jqx-scrollbar-button-state-hover-iotify .jqx-icon-arrow-down-hover-iotify {
    background-image: var(--jqx-scrollbar-arrow-down-hovered-color-iotify);
}
.jqx-scrollbar-button-state-pressed-iotify .jqx-icon-arrow-down-selected-iotify {
    background-image: var(--jqx-scrollbar-arrow-down-active-color-iotify);
}

/* scrollbar arrow left */
.jqx-scrollbar-button-state-normal-iotify .jqx-icon-arrow-left-iotify {
    background-image: var(--jqx-scrollbar-arrow-left-color-iotify);
}
.jqx-scrollbar-button-state-hover-iotify .jqx-icon-arrow-left-hover-iotify {
    background-image: var(--jqx-scrollbar-arrow-left-hovered-color-iotify);
}
.jqx-scrollbar-button-state-pressed-iotify .jqx-icon-arrow-left-selected-iotify{
    background-image: var(--jqx-scrollbar-arrow-left-active-color-iotify);
}

/* scrollbar arrow right */
.jqx-scrollbar-button-state-normal-iotify .jqx-icon-arrow-right-iotify {
    background-image: var(--jqx-scrollbar-arrow-right-color-iotify);
}
.jqx-scrollbar-button-state-hover-iotify .jqx-icon-arrow-right-hover-iotify {
    background-image: var(--jqx-scrollbar-arrow-right-hovered-color-iotify);
}
.jqx-scrollbar-button-state-pressed-iotify .jqx-icon-arrow-right-selected-iotify {
    background-image: var(--jqx-scrollbar-arrow-right-active-color-iotify);
}

.jqx-scrollview-button-iotify {
    background-color: var(--jqx-scrollview-indicator-background-color-iotify);
}

.jqx-scrollview-button-selected-iotify {
    background-color: var(--jqx-scrollview-selected-indicator-background-color-iotify);
}

.jqx-progressbar-iotify {
    background: var(--jqx-progressbar-bar-background-color-iotify) !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.jqx-progressbar-value-iotify, .jqx-progressbar-value-vertical-iotify {
    background: var(--jqx-progressbar-selected-range-background-color-iotify);
}

.jqx-progressbar-text-iotify {
    color: var(--jqx-progressbar-label-color-iotify);
}

.jqx-splitter-collapse-button-vertical-iotify, .jqx-splitter-collapse-button-horizontal-iotify {
    background: var(--jqx-accent-color-iotify);
}


.jqx-scrollbar-thumb-state-pressed-iotify, .jqx-splitter-splitbar-vertical-iotify, .jqx-splitter-splitbar-horizontal-iotify, .jqx-scrollbar-thumb-state-pressed-horizontal-iotify,
.jqx-scrollbar-button-state-pressed-iotify {
    background: var(--jqx-scrollbar-scrollbar-thumb-active-background-color-iotify);
    border-color: #b3b3b3;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.jqx-grid-column-sortdescbutton-iotify, jqx-grid-column-filterbutton-iotify, .jqx-grid-column-sortascbutton-iotify {
    background-color: transparent;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: var(--jqx-border-color-iotify);
}

.jqx-menu-vertical-iotify, .jqx-menu-horizontal-iotify {
    background: var(--jqx-menu-background-color-iotify);
    filter: none;
}

.jqx-menu-dropdown-iotify {
    background-color: var(--jqx-menu-dropdown-background-color-iotify);
}

.jqx-grid-bottomright-iotify, .jqx-panel-bottomright-iotify, .jqx-listbox-bottomright-iotify {
    background-color: #fafafa;
}

.jqx-window-iotify, .jqx-tooltip-iotify {
    box-shadow: 0 4px 23px 5px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0,0,0,0.15);
}
.jqx-tooltip-iotify, .jqx-tooltip-iotify.jqx-popup-iotify, .jqx-tooltip-iotify .jqx-fill-state-normal-iotify {
    background: var(--jqx-overlays-tooltip-background-color-iotify);
    border-color: var(--jqx-overlays-tooltip-background-color-iotify);
    box-shadow: none;
    color: var(--jqx-overlays-tooltip-text-color-iotify);
}
.jqx-docking-iotify .jqx-window-iotify {
    box-shadow: none;
}

.jqx-docking-panel-iotify .jqx-window-iotify {
    box-shadow: none;
}

.jqx-checkbox-iotify {
    line-height:20px;
    overflow: visible;
}
.jqx-radiobutton-iotify {
    overflow: visible;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    background-repeat: no-repeat;
    background: none;
    line-height:20px;
}

.jqx-radiobutton-iotify-iotify, .jqx-radiobutton-hover-iotify {
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    background-repeat: no-repeat;
    transition: background-color ease-in .3s;
}

.jqx-radiobutton-check-checked-iotify {
    filter: none;
    background: var(--jqx-background-color-iotify);
    background-repeat: no-repeat;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.jqx-radiobutton-check-indeterminate-iotify {
    filter: none;
    background: #999;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.jqx-radiobutton-check-indeterminate-disabled-iotify {
    filter: none;
    background: #999;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.jqx-checkbox-default-iotify,
.jqx-radiobutton-default-iotify
 {
    border-width: 1px;
    border-color: var(--jqx-border-color-iotify);
    background-color: var(--jqx-background-color-iotify);
    overflow: visible;
}

.jqx-tree-iotify .jqx-checkbox-iotify .jqx-checkbox-default-iotify,
.jqx-checkbox-iotify[checked] .jqx-checkbox-default-iotify,
.jqx-tree-grid-checkbox[checked].jqx-checkbox-default-iotify,
.jqx-radiobutton-iotify[checked] .jqx-radiobutton-default-iotify
 {
    background-color: var(--jqx-accent-color-iotify);
    border-color: var(--jqx-accent-color-iotify);
}

.jqx-checkbox-check-checked-iotify {
    background: transparent url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material_check_white.png) center center no-repeat;
}
.jqx-checkbox-check-indeterminate-iotify {
    width:14px !important;
    height:14px !important;
    position:relative;
    top: 1px;
    left: 1px;
    background: white;
}
.jqx-tree-iotify .jqx-checkbox-check-indeterminate-iotify {
    width:12px !important;
    height:12px !important;
    top: 2px;
    left:2px;
}

.jqx-checkbox-hover-iotify,
.jqx-radiobutton-hover-iotify {
    background-color: var(--jqx-accent-color-iotify);
    border-color: var(--jqx-accent-color-iotify);
}


.jqx-slider-slider-iotify {
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s ease;
}

.jqx-slider-slider-iotify:active {
    transform: scale(1.2);
    box-shadow: rgba(0,0,0,0.3) 0 0 10px;
}
.jqx-slider-iotify[discrete] .jqx-slider-slider-iotify:active
 {
    transform: scaleX(0);
    
}
.jqx-slider-slider-horizontal-iotify, .jqx-slider-slider-vertical-iotify {
    background: var(--jqx-slider-slider-thumb-color-iotify);
    border-color: var(--jqx-slider-slider-thumb-color-iotify);  
}

.jqx-slider-has-value-iotify.jqx-fill-state-focus-iotify,
.jqx-slider-button-iotify.jqx-fill-state-focus-iotify {
    border-color: var(--jqx-slider-focused-border-color-iotify) !important;
}

.jqx-slider-tooltip-iotify {
    width: 25px;
    height: 25px;
    transform-origin: 50% 100%;
    border-radius: 50%;
    transform: scale(0) rotate(45deg);
    padding:0px;
    background: transparent !important;
}
.jqx-slider-tooltip-iotify.init {
     transform: scale(1) rotate(45deg);
}
.jqx-slider-tooltip-iotify.hide {
     transition: transform 0.2s ease;
     transform-origin:50% 100%;
     transform: scale(0) rotate(45deg); 
}
.jqx-slider-tooltip-iotify.show {
     transition: transform 0.2s ease;
     transform: scale(1) rotate(45deg); 
}


.jqx-slider-tooltip-iotify .jqx-tooltip-arrow-t-b,
.jqx-slider-tooltip-iotify .jqx-tooltip-arrow-l-r {
    display:none;
    visibility:hidden;
}

.jqx-slider-tooltip-iotify, .jqx-slider-tooltip-iotify .jqx-fill-state-normal-iotify {
    border-radius: 15px 15px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--jqx-slider-tooltip-background-color-iotify); 
    color: var(--jqx-slider-tooltip-text-color-iotify);
    border-color: var(--jqx-slider-tooltip-border-color-iotify);
    font-size:11px;
}
.jqx-slider-tooltip-iotify.far, .jqx-slider-tooltip-iotify.far .jqx-fill-state-normal-iotify {
   border-radius: 0px 15px 15px 15px; 
}
.jqx-slider-tooltip-iotify.vertical, .jqx-slider-tooltip-iotify.vertical .jqx-fill-state-normal-iotify {
   border-radius: 15px 0px 15px 15px; 
}
.jqx-slider-tooltip-iotify.vertical.far, .jqx-slider-tooltip-iotify.vertical.far .jqx-fill-state-normal-iotify {
   border-radius: 15px 15px 15px 0px; 
}
.jqx-slider-tooltip-iotify {
    background:transparent;
    border:none !important;
    box-shadow:none;
}
.jqx-slider-tooltip-iotify .jqx-tooltip-main-iotify {
    top: -7px;
    right: 11px;
}
.jqx-slider-tooltip-iotify.far .jqx-tooltip-main-iotify {
    top: 3px;
    right: 4px;
}
.jqx-slider-tooltip-iotify.vertical .jqx-tooltip-main-iotify {
    top: -3px;
    right: 3px;
}
.jqx-slider-tooltip-iotify .jqx-tooltip-text {
    background: transparent;
    border:none;
    padding: 0px;
    overflow:visible;
}
.jqx-slider-tooltip-iotify .jqx-tooltip-text>span {
     transform: rotate(-45deg);
}
.jqx-slider-tooltip-iotify.range {
    width: 35px;
    height:35px;
}

.jqx-slider-rangebar-iotify {
    border-color: var(--jqx-slider-selected-slider-background-color-iotify);
    background: var(--jqx-slider-selected-slider-background-color-iotify);
}

.jqx-slider-track-horizontal-iotify, .jqx-slider-track-vertical-iotify {
    border-color: var(--jqx-border-color-iotify);
    background: var(--jqx-slider-slider-bar-background-color-iotify);
}

.jqx-slider-button-iotify {
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}
.jqx-slider-button-iotify.jqx-fill-state-normal-iotify {
    background: var(--jqx-slider-arrows-background-color-iotify) !important;
}

.jqx-slider-button-iotify.jqx-fill-state-hover-iotify {
    background: var(--jqx-slider-arrows-hovered-background-color-iotify) !important;
}

.jqx-slider-button-iotify.jqx-fill-state-pressed-iotify {
    background: var(--jqx-slider-arrows-selected-background-color-iotify) !important;
}

.jqx-listitem-state-hover-iotify,
.jqx-listitem-state-selected-iotify,
.jqx-listitem-state-normal-iotify {
    padding: 5px;
    margin:0px;
}

.jqx-listitem-state-normal-iotify, .jqx-listmenu-item-iotify.jqx-fill-state-normal-iotify {
    color: var(--jqx-list-text-color-iotify);
    background-color: var(--jqx-list-background-color-iotify);
}

.jqx-listitem-state-group-iotify, .jqx-listmenu-header-iotify {
    color: var(--jqx-list-header-text-color-iotify);
    background-color: var(--jqx-list-header-background-color-iotify);
}

.jqx-menu-item-top-iotify {
    color: var(--jqx-menu-text-color-iotify);
}
.jqx-menu-item-iotify {
    color: var(--jqx-menu-dropdown-text-color-iotify);
}

.jqx-scheduler-edit-dialog-label-iotify {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;

}
.jqx-scheduler-edit-dialog-field-iotify {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.jqx-scheduler-edit-dialog-label-rtl-iotify {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.jqx-scheduler-edit-dialog-field-rtl-iotify {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}

/*applied to a list item when the item is selected.*/ 
.jqx-input-popup-iotify .jqx-fill-state-hover-iotify,
.jqx-input-popup-iotify .jqx-fill-state-pressed-iotify {
    color: var(--jqx-hovered-state-content-text-color-iotify) !important;
    border-color: var(--jqx-hovered-state-content-background-color-iotify);
    text-decoration: none;
    background-color: var(--jqx-hovered-state-content-background-color-iotify);
    background-repeat: repeat-x;
    outline: 0;
    background: var(--jqx-hovered-state-content-background-color-iotify); /* Old browsers */
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-position: 0 0;
}

.jqx-tree-item-hover-iotify {
    color: var(--jqx-treeview-hovered-tree-item-color-iotify) !important;
    border-color: var(--jqx-treeview-hovered-tree-item-background-color-iotify);
    background-color: var(--jqx-treeview-hovered-tree-item-background-color-iotify);
    background: var(--jqx-treeview-hovered-tree-item-background-color-iotify); /* Old browsers */
}

.jqx-menu-item-hover-iotify, .jqx-menu-vertical-iotify .jqx-menu-item-top-hover-iotify,
.jqx-menu-item-top-iotify.jqx-fill-state-hover-iotify {
    color: var(--jqx-menu-hovered-item-color-iotify);
    background-color: var(--jqx-menu-hovered-item-background-color-iotify);
    border-color: var(--jqx-menu-hovered-item-background-color-iotify);
}

.jqx-listitem-state-hover-iotify {
    color: var(--jqx-list-hovered-item-state-text-color-iotify) !important;
    border-color: var(--jqx-list-hovered-item-state-background-color-iotify);
    background-color: var(--jqx-list-hovered-item-state-background-color-iotify);
}

.jqx-grid-cell-hover-iotify {
    color: var(--jqx-grid-hovered-row-text-color-iotify) !important;
    background-color: var(--jqx-grid-hovered-row-background-color-iotify) !important;
}

.jqx-scheduler-iotify {
    border-color: var(--jqx-scheduler-header-border-color-iotify);
}

.jqx-scheduler-iotify .jqx-scrollbar-state-normal-iotify {
    border-left-color: var(--jqx-scheduler-header-border-color-iotify);
}

.jqx-scheduler-iotify .jqx-widget-header-iotify{
    color: var(--jqx-scheduler-header-text-color-iotify) !important;
    background-color: var(--jqx-scheduler-header-background-color-iotify) !important;
    border-color: var(--jqx-scheduler-header-border-color-iotify) !important;
}

.jqx-scheduler-all-day-cell-iotify {
    border-color: var(--jqx-scheduler-header-border-color-iotify) !important;
}

.jqx-scheduler-toolbar-iotify .jqx-datetimeinput-iotify {
    border-color: var(--jqx-scheduler-header-border-color-iotify) !important;
}

.jqx-scheduler-toolbar-iotify .jqx-group-button-normal-iotify {
    color: var(--jqx-scheduler-header-buttons-text-color-iotify);
    border-color: var(--jqx-scheduler-header-buttons-background-color-iotify);
    background: var(--jqx-scheduler-header-buttons-background-color-iotify);
}
.jqx-scheduler-toolbar-iotify .jqx-group-button-normal-iotify.jqx-fill-state-hover-iotify {
    color: var(--jqx-scheduler-header-buttons-hovered-text-color-iotify);
    background: var(--jqx-scheduler-header-buttons-hovered-background-color-iotify);
}
.jqx-scheduler-toolbar-iotify .jqx-group-button-normal-iotify.jqx-fill-state-pressed-iotify {
    color: var(--jqx-scheduler-header-buttons-selected-text-color-iotify) !important;
    border-color: var(--jqx-scheduler-header-buttons-selected-background-color-iotify) !important;
    background-color: var(--jqx-scheduler-header-buttons-selected-background-color-iotify) !important;
}

.jqx-scheduler-work-time-cell-iotify, .jqx-scheduler-not-work-time-cell-iotify {
    border-color: var(--jqx-scheduler-border-color-iotify) !important; 
}

.jqx-scheduler-not-work-time-cell-iotify {
    background-color: var(--jqx-scheduler-not-work-time-cells-color-iotify);
}

.jqx-scheduler-cell-hover-iotify {
    border-color: var(--jqx-scheduler-hovered-cell-border-color-iotify) !important;
    background: var(--jqx-scheduler-hovered-cell-background-color-iotify) !important;
}

.jqx-scheduler-toolbar-iotify .jqx-datetimeinput {
    background-color: inherit !important;
}
.jqx-scheduler-toolbar-iotify .jqx-datetimeinput .jqx-action-button-iotify{
    background-color: inherit !important;
}

.jqx-scheduler-toolbar-iotify .jqx-icon-arrow-left-iotify {
    background-image: var(--jqx-scheduler-weeks-arrow-left-color-iotify);
}
.jqx-scheduler-toolbar-iotify .jqx-icon-arrow-right-iotify {
    background-image: var(--jqx-scheduler-weeks-arrow-right-color-iotify);
}
.jqx-scheduler-toolbar-iotify .jqx-icon-arrow-left-selected-iotify {
    background-image: var(--jqx-scheduler-weeks-arrow-left-selected-color-iotify);
}
.jqx-scheduler-toolbar-iotify .jqx-icon-arrow-right-selected-iotify {
    background-image: var(--jqx-scheduler-weeks-arrow-right-selected-color-iotify);
}

.jqx-scheduler-toolbar-iotify .jqx-icon-calendar {
    background-image: var(--jqx-scheduler-calendar-icon-color-iotify);
}
.jqx-scheduler-toolbar-iotify .jqx-icon-calendar-pressed {
    background-image: var(--jqx-scheduler-calendar-icon-selected-color-iotify);
}


.jqx-grid-selectionarea-iotify,
.jqx-input-button-header-iotify, .jqx-input-button-innerHeader-iotify {
    color: var(--jqx-accent-color-iotify) !important;
    border-color: #E1F5FE !important;
    background: #E1F5FE; /* Old browsers */
    box-shadow: none;
}

.jqx-tree-item-selected-iotify {
    color: var(--jqx-treeview-selected-tree-item-color-iotify) !important;
    border-color: var(--jqx-treeview-selected-tree-item-background-color-iotify) !important;
    background: var(--jqx-treeview-selected-tree-item-background-color-iotify); /* Old browsers */
    box-shadow: none;
}

.jqx-menu-item-selected-iotify, .jqx-menu-vertical-iotify .jqx-menu-item-top-selected-iotify, .jqx-menu-item-top-selected-iotify {
    color: var(--jqx-menu-selected-item-color-iotify) !important;
    border-color: var(--jqx-menu-selected-item-border-color-iotify) !important;
    background-color: var(--jqx-menu-selected-item-background-color-iotify) !important;
    background: var(--jqx-menu-selected-item-background-color-iotify); /* Old browsers */
}

.jqx-listitem-state-selected-iotify, .jqx-listmenu-item-iotify.jqx-fill-state-pressed-iotify {
    color: var(--jqx-list-selected-item-state-text-color-iotify) !important;
    border-color: var(--jqx-list-selected-item-state-background-color-iotify) !important;
    background: var(--jqx-list-selected-item-state-background-color-iotify); /* Old browsers */
}

.jqx-grid-cell-selected-iotify {
    color: var(--jqx-grid-selected-row-text-color-iotify) !important;
    border-color: var(--jqx-grid-selected-row-border-color-iotify) !important;
    background-color: var(--jqx-grid-selected-row-background-color-iotify) !important;
    background: var(--jqx-grid-selected-row-background-color-iotify); /* Old browsers */
    box-shadow: none;
}

.jqx-scheduler-cell-selected-iotify {
    border-color: var(--jqx-scheduler-selected-cell-border-color-iotify) !important;
    background-color: var(--jqx-scheduler-selected-cell-background-color-iotify) !important;
}

.jqx-grid-column-header-iotify .sorticon {
    background-color: inherit !important;
}
.jqx-grid-column-header-iotify .jqx-widget-header-iotify {
    background-color: inherit !important;
}
.jqx-widget-header-iotify.sortasc, .jqx-grid-column-sortascbutton-iotify,
.jqx-widget-header-iotify.sortdesc, .jqx-grid-column-sortdescbutton-iotify {
    background-color: inherit !important;
}

.jqx-grid-cell-iotify .jqx-button-iotify, .jqx-grid-cell-iotify .jqx-button-iotify.jqx-fill-state-hover-iotify, .jqx-grid-cell-iotify .jqx-button-iotify.jqx-fill-state-pressed-iotify {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}

.jqx-grid-cell-selected.jqx-grid-cell-edit-iotify input {
    background-color: var(--jqx-grid-editor-background-color-iotify) !important;
    color: var(--jqx-grid-editor-text-color-iotify) !important;
}

#tabletreegrid .jqx-cell-editor-iotify {
    background-color: var(--jqx-grid-editor-background-color-iotify) !important;
    color: var(--jqx-grid-editor-text-color-iotify) !important;
}

.jqx-popup-iotify {
    border: 1px solid var(--jqx-border-color-iotify);
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.jqx-grid-column-sortascbutton-iotify, .jqx-expander-arrow-bottom-iotify, .jqx-window-collapse-button-iotify, .jqx-menu-item-arrow-up-iotify, .jqx-menu-item-arrow-up-selected-iotify, .jqx-menu-item-arrow-top-up-iotify, .jqx-icon-arrow-up-iotify, .jqx-icon-arrow-up-hover-iotify, .jqx-icon-arrow-up-selected-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-up.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-widget-iotify .jqx-grid-group-expand-iotify, .jqx-grid-group-expand-iotify, .jqx-grid-column-menubutton-iotify, .jqx-grid-column-sortdescbutton-iotify, .jqx-expander-arrow-top-iotify, .jqx-window-collapse-button-collapsed-iotify, .jqx-menu-item-arrow-down-iotify, .jqx-menu-item-arrow-down-selected-iotify, .jqx-menu-item-arrow-down-iotify, .jqx-icon-arrow-down-iotify {
    background-image: var(--jqx-arrow-down-color-iotify); 
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-down-hover-iotify {
    background-image: var(--jqx-arrow-down-hovered-color-iotify);
}

.jqx-icon-arrow-down-selected-iotify {
    background-image: var(--jqx-arrow-down-selected-color-iotify);
}

.jqx-icon-arrow-left-iotify, .jqx-icon-arrow-down-left-iotify, .jqx-icon-arrow-left-selected-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-tabs-arrow-left-iotify {
    background-image: var(--jqx-tabs-arrow-left-color-iotify);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-tabs-arrow-right-iotify {
    background-image: var(--jqx-tabs-arrow-right-color-iotify);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-widget-iotify .jqx-grid-group-collapse-iotify, .jqx-grid-group-collapse-iotify, .jqx-icon-arrow-right-iotify, .jqx-icon-arrow-right-hover-iotify, .jqx-icon-arrow-right-selected-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-menu-item-arrow-top-left-iotify {
    background-image: var(--jqx-menu-arrow-left-color-iotify);
    background-repeat: no-repeat;
    background-position: center;
}
.jqx-menu-item-arrow-top-right-iotify {
    background-image: var(--jqx-menu-arrow-right-color-iotify);
    background-repeat: no-repeat;
    background-position: center;
}



.jqx-grid-cell-hover-iotify .jqx-grid-group-expand-iotify.jqx-icon-arrow-down-iotify {
    background-image: var(--jqx-grid-arrow-down-hovered-color-iotify);
}
.jqx-grid-cell-hover-iotify .jqx-grid-group-expand-rtl-iotify.jqx-icon-arrow-down-iotify {
    background-image: var(--jqx-grid-arrow-down-hovered-color-iotify);
}
.jqx-grid-cell-hover-iotify .jqx-grid-group-collapse-iotify.jqx-icon-arrow-right-iotify {
    background-image: var(--jqx-grid-arrow-rigth-hovered-color-iotify);
}
.jqx-grid-cell-hover-iotify .jqx-grid-group-collapse-rtl-iotify.jqx-icon-arrow-left-iotify {
    background-image: var(--jqx-grid-arrow-left-hovered-color-iotify);
}

.jqx-grid-group-collapse-iotify.jqx-icon-arrow-right-iotify {
    background-image: var(--jqx-grid-groups-arrow-right-color-iotify);
}
 .jqx-grid-group-expand-iotify.jqx-icon-arrow-down-iotify {
    background-image: var(--jqx-grid-groups-arrow-down-color-iotify);
}

.jqx-grid-group-collapse-rtl-iotify.jqx-icon-arrow-left-iotify {
    background-image: var(--jqx-grid-groups-arrow-left-color-iotify);
}
.jqx-grid-group-expand-rtl-iotify.jqx-icon-arrow-down-iotify {
    background-image: var(--jqx-grid-groups-arrow-down-color-iotify);
} 

.jqx-tree-grid-collapse-button-iotify.jqx-icon-arrow-right-iotify{
    background-image: var(--jqx-grid-arrow-right-color-iotify);
}
.jqx-tree-grid-collapse-button-iotify.jqx-icon-arrow-left-iotify{
    background-image: var(--jqx-grid-arrow-left-color-iotify);
}
.jqx-tree-grid-expand-button-iotify.jqx-icon-arrow-down-iotify {
    background-image: var(--jqx-grid-arrow-down-color-iotify);
}

.jqx-grid-pager-iotify .jqx-icon-arrow-left-iotify {
    background-image: var(--jqx-grid-arrow-left-color-iotify);
}
.jqx-grid-pager-iotify .jqx-icon-arrow-left-hover-iotify {
    background-image: var(--jqx-grid-arrow-left-hovered-color-iotify);
}
.jqx-grid-pager-iotify .jqx-icon-arrow-left-selected-iotify {
    background-image: var(--jqx-grid-arrow-left-selected-color-iotify);
}
.jqx-grid-pager-iotify .jqx-icon-arrow-right-iotify {
    background-image: var(--jqx-grid-arrow-right-color-iotify);
}
.jqx-grid-pager-iotify .jqx-icon-arrow-right-hover-iotify {
    background-image: var(--jqx-grid-arrow-right-hovered-color-iotify);
}
.jqx-grid-pager-iotify .jqx-icon-arrow-right-selected-iotify {
    background-image: var(--jqx-grid-arrow-right-selected-color-iotify);
}

.jqx-grid-column-sorticon-iotify.jqx-icon-arrow-down-iotify {
    background-image: var(--jqx-grid-header-arrow-down-color-iotify);
}
.jqx-grid-column-sortdescbutton-iotify {
    background-image: var(--jqx-grid-header-arrow-down-color-iotify);
}
.jqx-grid-column-sortascbutton-iotify {
    background-image: var(--jqx-grid-header-arrow-up-color-iotify);
}
.jqx-grid-column-menubutton-iotify {
    background-image: var(--jqx-grid-menu-button-color-iotify) !important;
}

.jqx-grid-group-column-iotify .jqx-grid-column-sortdescbutton-iotify {
    background-image: var(--jqx-grid-arrow-down-color-iotify);
}

.jqx-tree-item-arrow-collapse-rtl-iotify, .jqx-tree-item-arrow-collapse-hover-rtl-iotify {
    background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png);
}

.jqx-menu-item-arrow-left-selected-iotify {
    background-image: var(--jqx-menu-arrow-left-selected-color-iotify);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-menu-item-arrow-right-selected-iotify {
    background-image: var(--jqx-menu-arrow-right-selected-color-iotify);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-tree-item-arrow-expand-iotify {
    background-image: var(--jqx-treeview-arrow-expanded-color-iotify) !important;
}

.jqx-tree-item-arrow-collapse-iotify
{
    transform: rotate(-90deg);
    background-image: var(--jqx-treeview-arrow-collapsed-color-iotify);
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.2s ease-out;
}

.jqx-navbar-iotify {
    border-color: var(--jqx-navbar-item-background-color-iotify) !important;
}

.jqx-navbar-block-iotify {
    color: var(--jqx-navbar-item-text-color-iotify) !important;
    background: var(--jqx-navbar-item-background-color-iotify) !important;
    border-color: var(--jqx-navbar-item-background-color-iotify) !important;
}

.jqx-navbar-block-iotify.jqx-fill-state-hover-iotify {
    color: var(--jqx-navbar-hovered-item-text-color-iotify) !important;
    background-color: var(--jqx-navbar-hovered-item-background-color-iotify) !important;
    border-color: var(--jqx-navbar-hovered-item-background-color-iotify) !important;
    opacity: 1
}

.jqx-navbar-block-iotify.jqx-fill-state-pressed-iotify {
    color: var(--jqx-navbar-selected-item-text-color-iotify) !important;
    background-color: var(--jqx-navbar-selected-item-background-color-iotify) !important;
    border-color: var(--jqx-navbar-selected-item-background-color-iotify) !important;
}

.jqx-input-button-content-iotify {
    font-size: 10px;
}

.jqx-widget .jqx-grid-column-header-cell-iotify {
    padding-top: 8px;
    padding-bottom: 8px;
    height:30px;
}

.jqx-widget .jqx-grid-row-cell-iotify {
    padding-top: 8px;
    padding-bottom: 8px;
    height:30px;
}

.jqx-widget .jqx-grid-cell-iotify, .jqx-widget .jqx-grid-column-header-iotify, .jqx-widget .jqx-grid-group-cell-iotify {
    border-color: var(--jqx-border-color-iotify);
}

.jqx-input-iotify, .jqx-input-iotify input, .jqx-maskedinput-iotify {
    border-radius: var(--jqx-editors-text-editors-border-radius-iotify) !important;
}

.jqx-combobox-iotify .jqx-icon-close-iotify {
    background-image:url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/close_white.png)
}
.jqx-combobox-iotify, .jqx-input-iotify {
    border-color: var(--jqx-editors-text-editors-border-color-iotify);
    color: var(--jqx-editors-text-editors-text-color-iotify);
    background-color: var(--jqx-editors-text-editors-background-color-iotify);
    border-left: none;
    border-right: none;
    border-top: none;
    box-shadow: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.jqx-combobox-content-iotify,
.jqx-datetimeinput-content-iotify
 {
    border-color: transparent;
}
.jqx-combobox-arrow-normal-iotify {
    background: var(--jqx-background-color-iotify);
    border-color: transparent;
}

.jqx-combobox-content-focus-iotify, 
.jqx-combobox-state-focus-iotify,
.jqx-numberinput-focus-iotify {
    outline: none;
}

.jqx-combobox-iotify, .jqx-input-iotify {
    box-shadow: none;
}

.jqx-input-group-iotify {
    position: relative;
    display: inline-block;
    overflow: visible;
    border: none;
    box-shadow: none;
}

    .jqx-input-group-iotify input {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .jqx-input-group-iotify textarea {
        width: 100%;
        height: 100%;
        outline: none;
        resize: none;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom-color: var(--jqx-border-color-iotify);
    }
.jqx-numberinput-iotify,
.jqx-maskedinput-iotify
 {
    position:relative;
}
.jqx-numberinput-iotify input {
    height:100% !important;
}

.jqx-input-iotify.jqx-validator-error-element {
    border-color: transparent !important;
    border-bottom: 1px solid #df2227 !important; 
}
.jqx-input-iotify input,
.jqx-dropdownlist-state-normal-iotify,
.jqx-combobox-state-normal-iotify,
.jqx-datetimeinput-iotify,
.jqx-numberinput-iotify,
.jqx-maskedinput-iotify
 {
    background: var(--jqx-editors-text-editors-background-color-iotify);
    color: var(--jqx-editors-text-editors-text-color-iotify);
    border-color: #fff;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid var(--jqx-editors-text-editors-border-color-iotify);
    outline: none;
}
.jqx-datetimeinput-iotify .jqx-action-button-iotify,
.jqx-datetimeinput-iotify .jqx-action-button-rtl-iotify 
 {
    background-color: transparent;
    border-color: transparent;
}
    .jqx-datetimeinput-iotify, .jqx-datetimeinput-iotify > div,
    .jqx-numberinput-iotify, .jqx-numberinput-iotify > div,
    .jqx-maskedinput-iotify, .jqx-maskedinput-iotify > div,
    .jqx-dropdownlist-state-normal-iotify, .jqx-dropdownlist-state-normal-iotify > div, .jqx-dropdownlist-state-normal-iotify > div > div,
    .jqx-combobox-state-normal-iotify, .jqx-combobox-state-normal-iotify > div, .jqx-combobox-state-normal-iotify > div > div {
        overflow: visible !important;
    }

    .jqx-input-iotify input:focus {
        border-radius: 0;
        box-shadow: none;
    }

.jqx-input-iotify input, input[type="text"].jqx-input-iotify, input[type="password"].jqx-input-iotify, input[type="text"].jqx-widget-content-iotify, input[type="textarea"].jqx-widget-content-iotify, textarea.jqx-input-iotify {
    font-size: 14px;
    resize: none;
    background: var(--jqx-editors-text-editors-background-color-iotify);
    color: var(--jqx-editors-text-editors-text-color-iotify);
    border: none;
    border-radius: 0;
    box-sizing:border-box;
    box-shadow: none;
    border-bottom: 1px solid var(--jqx-editors-text-editors-border-color-iotify);
}
.jqx-input-label-iotify {
    visibility:inherit;
}
.jqx-input-bar-iotify{
    visibility:inherit;
}
input:focus ~ .jqx-input-label-iotify,
textarea:focus ~ .jqx-input-label-iotify,
.jqx-input-widget-iotify[hint=true] .jqx-input-label,
.jqx-text-area-iotify[hint=true] .jqx-input-label,
.jqx-dropdownlist-state-selected-iotify .jqx-input-label,
.jqx-dropdownlist-state-normal-iotify[hint=true] .jqx-input-label,
.jqx-combobox-state-normal-iotify[hint=true] .jqx-input-label,
.jqx-combobox-iotify .jqx-input-label.focused,
.jqx-dropdownlist-iotify .jqx-input-label.focused,
.jqx-datetimeinput-iotify[hint=true] .jqx-input-label,
.jqx-maskedinput-iotify[hint=true] .jqx-input-label,
.jqx-numberinput-iotify[hint=true] .jqx-input-label,
.jqx-formattedinput-iotify[hint=true] .jqx-input-label
 {
    top: -15px;
    font-size: 12px;
    color: var(--jqx-editors-text-editors-focused-border-color-iotify);
}

.jqx-combobox-iotify #dropdownlistContentcombobox {
    background-color: var(--jqx-editors-text-editors-background-color-iotify);
}
.jqx-dropdownlist-iotify[default-placeholder="true"] .jqx-input-label {
    visibility: hidden;
}


input:focus ~ .jqx-input-bar:before,
textarea:focus ~ .jqx-input-bar:before,
.jqx-dropdownlist-state-selected-iotify .jqx-input-bar:before,
.jqx-dropdownlist-iotify .jqx-input-bar.focused:before,
.jqx-combobox-iotify .jqx-input-bar.focused:before,
.jqx-complex-input-group-iotify .jqx-input-bar.focused::before,
.jqx-combobox-state-selected-iotify .jqx-input-bar:before {
    width: 100%;
}
.jqx-complex-input-group-iotify .jqx-fill-state-normal-iotify {
    border-color: #fafafa;
}
.jqx-input-widget-iotify input[type="password"]{
    letter-spacing: 0.3em;
}

.jqx-input-label-iotify {
    color: var(--jqx-border-color-iotify);
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 2px;
    top:10px;
    top: calc(50% - 7px);
    transition: 300ms ease all;
}
.jqx-input-label.initial {
    transition: none;
}
.jqx-input-bar-iotify {
    position: relative;
    display: block;
    z-index:1;
}

    .jqx-input-bar-iotify:before {
        content: '';
        height: 2px;
        width: 0;
        bottom: 0px;
        position: absolute;
        background: var(--jqx-editors-text-editors-focused-border-color-iotify);
        transition: 300ms ease all;
        left: 0%;
    }
.jqx-formatted-input-spin-button-iotify, .jqx-input-group-addon-iotify {
    border-color: #fff;
    background: #fff;
}
.jqx-dropdownlist-state-selected-iotify,
.jqx-combobox-state-selected-iotify {
    color: var(--jqx-accent-color-iotify);
}

.jqx-switchbutton-thumb-iotify {
    border-color: var(--jqx-editors-switch-thumb-color-iotify);
    background: var(--jqx-background-color-iotify);
}

.jqx-switchbutton-label-on-iotify {
    color: var(--jqx-editors-switch-on-label-text-color-iotify);
    background-color: var(--jqx-editors-switch-on-label-background-color-iotify);
}

.jqx-switchbutton-label-off-iotify {
    color: var(--jqx-editors-switch-off-label-text-color-iotify);
    background-color: var(--jqx-editors-switch-off-label-background-color-iotify);
}

.jqx-file-upload-iotify, .jqx-file-upload-file-row-iotify {
    background-color: var(--jqx-editors-file-uploader-background-color-iotify);
}
.jqx-file-upload-file-name-iotify {
    color: var(--jqx-editors-file-uploader-filename-text-color-iotify) !important;
}

.jqx-dropdownlist-state-normal-iotify .jqx-icon-arrow-down-iotify,
.jqx-combobox-state-normal-iotify .jqx-icon-arrow-down-iotify,
.sorticon.descending .jqx-grid-column-sorticon-iotify,
.jqx-tree-item-arrow-expand-iotify,
 .jqx-expander-header-iotify .jqx-icon-arrow-down
 {
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
}

.jqx-dropdownlist-state-hover-iotify {
    background-color: var(--jqx-hovered-state-background-color-iotify);
    color: var(--jqx-hovered-state-text-color-iotify);
}

.jqx-expander-header-iotify .jqx-icon-arrow-up {
   transform: rotate(180deg);
   transition: transform 0.2s ease-out;
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png');
}

.jqx-expander-arrow-top-iotify {
    background-image: var(--jqx-accordion-arrow-down-color-iotify);
}

.jqx-expander-arrow-top-hover-iotify {
    background-image: var(--jqx-accordion-arrow-down-hovered-color-iotify) !important;
}

.jqx-expander-arrow-expanded-iotify {
    background-image: var(--jqx-accordion-arrow-down-selected-color-iotify) !important;
}

.jqx-dropdownlist-state-selected-iotify .jqx-icon-arrow-down-iotify,
.jqx-combobox-state-selected-iotify .jqx-icon-arrow-down-iotify,
.sorticon.ascending .jqx-grid-column-sorticon-iotify
 {
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
    left: -1px;
}
.jqx-combobox-state-selected-iotify .jqx-icon-arrow-down-iotify{
    left:-1px;
}
.jqx-listbox-container {
    margin-top: 1px;
}

input[type="text"].jqx-input-iotify:-moz-placeholder, input[type="text"].jqx-widget-content-iotify:-moz-placeholder, input[type="textarea"].jqx-widget-content-iotify:-moz-placeholder, textarea.jqx-input-iotify:-moz-placeholder {
    color: #999999;
}

input[type="text"].jqx-input-iotify:-webkit-input-placeholder, input[type="text"].jqx-widget-content-iotify:-webkit-input-placeholder, input[type="textarea"].jqx-widget-content-iotify:-webkit-input-placeholder, textarea.jqx-input-iotify:-webkit-input-placeholder {
    color: #999999;
}

input[type="text"].jqx-input-iotify:-ms-input-placeholder, input[type="text"].jqx-widget-content-iotify:-ms-input-placeholder, input[type="textarea"].jqx-widget-content-iotify:-ms-input-placeholder, textarea.jqx-input-iotify:-ms-input-placeholder {
    color: #999999;
}

.jqx-combobox-content-focus-iotify, .jqx-combobox-state-focus-iotify, .jqx-fill-state-focus-iotify,
.jqx-numberinput-focus-iotify {
    outline: none;
}

.jqx-combobox-multi-item-iotify {
    color: var(--jqx-editors-combobox-multi-item-text-color-iotify);
    background: var(--jqx-editors-combobox-multi-item-background-color-iotify);
    border-color: var(--jqx-editors-combobox-multi-item-background-color-iotify);
}

.jqx-popup-iotify.jqx-fill-state-focus-iotify {
    outline: none;
    border-color: #E0E0E0 !important;
}

.jqx-datetimeinput-content, .jqx-datetimeinput-container {
    overflow: visible !important;
}
.jqx-text-area-iotify, .jqx-text-area-iotify > div {
    overflow:visible !important;
}
.jqx-text-area-element-iotify {
   box-sizing: border-box;
}
.jqx-pivotgrid-content-wrapper.jqx-fill-state-normal-iotify {
    border-color: var(--jqx-border-color-iotify);
}

.jqx-widget-header-iotify.jqx-pivotgrid-content-wrapper {
    background-color: var(--jqx-pivotgrid-header-background-color-iotify) !important;
    color: var(--jqx-pivotgrid-header-text-color-iotify) !important;
}

.jqx-grid-cell-normal-iotify.jqx-pivotgrid-content-wrapper {
    background-color: var(--jqx-pivotgrid-cell-background-color-iotify);
    color: var(--jqx-pivotgrid-cell-text-color-iotify);
}

.jqx-grid-cell-selected-iotify.jqx-pivotgrid-content-wrapper {
    color: var(--jqx-pivotgrid-selected-cell-text-color-iotify) !important;
    background-color: var(--jqx-pivotgrid-selected-cell-background-color-iotify) !important;
    border-color: var(--jqx-pivotgrid-selected-cell-background-color-iotify) !important;
}

.jqx-tabs-title-selected-top-iotify, .jqx-tabs-selection-tracker-top-iotify {
    border-color: transparent;
    filter: none;
    background: var(--jqx-tabs-selected-tab-background-color-iotify);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.jqx-grid-cell-filter-row-iotify {
    background-color: #fafafa;
}

.jqx-tabs-title-iotify, .jqx-ribbon-item-iotify {
    color: var(--jqx-tabs-tab-text-color-iotify);
}
.jqx-tabs-title-selected-bottom-iotify,
.jqx-tabs-title-selected-top-iotify
 {
    color: var(--jqx-tabs-selected-tab-text-color-iotify);
    font-weight:500;   
    padding-top:5px;
    padding-bottom:5px;
}
.jqx-tabs-title.jqx-fill-state-hover-iotify {
    border-color: transparent;
}
.jqx-ribbon-item-iotify {
    cursor: pointer;
}
.jqx-ribbon-item-selected-iotify {
    color: var(--jqx-tabs-selected-tab-text-color-iotify);
    font-weight:500;
    border-color: transparent;
}

.jqx-ribbon-item-hover-iotify {
    background: transparent;
}

.jqx-ribbon-header-top-iotify {
    border-color: transparent;
    border-bottom-color: var(--jqx-border-color-iotify);
}

.jqx-ribbon-header-bottom-iotify {
    border-color: transparent;
    border-top-color: var(--jqx-border-color-iotify);
}

.jqx-ribbon-header-right-iotify {
    border-color: transparent;
    border-left-color:var(--jqx-border-color-iotify);
}

.jqx-ribbon-header-left-iotify {
    border-color: transparent;
    border-right-color:var(--jqx-border-color-iotify);
}

.jqx-tabs-title-selected-bottom-iotify, .jqx-tabs-selection-tracker-bottom-iotify {
    border-color: transparent;
    border-top: 1px solid #fff;
    filter: none;
    background: var(--jqx-tabs-selected-tab-background-color-iotify);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.jqx-tabs-iotify, .jqx-ribbon-iotify {
    border-color: transparent;
}

.jqx-tabs-position-bottom .jqx-tabs-header-iotify {
    border-color: transparent;
}
.jqx-layout-iotify .jqx-tabs-header-iotify, .jqx-layout-iotify .jqx-ribbon-header-iotify {
    background: var(--jqx-background-color-iotify);
    border-color: var(--jqx-border-color-iotify);
}
.jqx-tabs-title-bottom {
    border-color: transparent;
}
.jqx-tabs-title-hover-top-iotify, .jqx-tabs-title-hover-bottom-iotify {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    color: var(--jqx-tabs-hovered-tab-text-color-iotify);
    background: var(--jqx-tabs-hovered-tab-background-color-iotify);
}

.jqx-tabs-header-iotify, .jqx-tabs-arrow-background-iotify {
    background-color: var(--jqx-tabs-header-background-color-iotify) !important;
    border-bottom: 1px solid var(--jqx-tabs-border-color-iotify);
}

.jqx-tabs-content-iotify {
    box-sizing: border-box;
    color: var(--jqx-tabs-content-text-color-iotify);
    background-color: var(--jqx-tabs-content-background-color-iotify);
    border: 1px solid var(--jqx-tabs-border-color-iotify);
    border-top-color: transparent;
    padding:5px;
}

.jqx-tabs-bar-iotify {
    position: absolute;
    bottom: 0;
    background: var(--jqx-tabs-selected-tab-bottom-border-color-iotify);
    height: 2px;
    z-index:10;
    transition: .5s cubic-bezier(.35,0,.25,1);
}
.jqx-tabs-bar-iotify.vertical {
    width: 2px;
}
.jqx-tabs-position-bottom .jqx-tabs-bar-iotify {
    top: 0;
}


.jqx-layout-iotify {
    background-color: #cccccc;
}

.jqx-kanban-column-header-collapsed-iotify {
    background: -moz-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(248,248,248,1)), color-stop(100%, rgba(234,234,234,1))); /* safari4+,chrome */
    background: -webkit-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* ie10+ */
    background: linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* w3c */
}


.jqx-calendar-cell-iotify {
    border-radius: 50%;
    font-size:12px;
}
.jqx-calendar-cell-year-iotify,
.jqx-calendar-cell-decade-iotify {
    border-radius: 25%;
}

.jqx-calendar-title-content-iotify {
    font-weight:bold;
}

.jqx-calendar-title-navigation-iotify.jqx-icon-arrow-left-iotify {
    background-image: var(--jqx-editors-calendar-arrow-left-color-iotify);
}

.jqx-calendar-title-navigation-iotify.jqx-icon-arrow-right-iotify {
    background-image: var(--jqx-editors-calendar-arrow-right-color-iotify);
}

.jqx-calendar-column-cell-iotify {
    color: var(--jqx-editors-calendar-header-text-color-iotify);
    font-size:12px;
}
.jqx-grid-column-menubutton-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png');
}

.jqx-tabs-close-button-iotify {
    background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/close.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-tabs-close-button-selected-iotify {
    background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/close.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-tabs-close-button-hover-iotify {
    background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/close.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-cell-iotify.jqx-grid-cell-selected-iotify>.jqx-grid-group-expand-iotify {
    background-image: var(--jqx-grid-arrow-down-selected-color-iotify);
    background-repeat: no-repeat;
    background-position: center;
}
.jqx-grid-cell-iotify.jqx-grid-cell-selected-iotify>.jqx-grid-group-collapse-iotify{
    background-image: var(--jqx-grid-arrow-right-selected-color-iotify);
    background-repeat: no-repeat;
    background-position: center;
}
.jqx-grid-cell-iotify.jqx-grid-cell-selected-iotify>.jqx-grid-group-collapse-rtl-iotify {
    background-image: var(--jqx-grid-arrow-left-selected-color-iotify);
    background-repeat: no-repeat;
    background-position: center;
}
.jqx-grid-cell-iotify.jqx-grid-cell-selected-iotify>.jqx-grid-group-expand-rtl-iotify{
    background-image: var(--jqx-grid-arrow-down-selected-color-iotify);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-group-collapse-iotify {
    background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-right.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.jqx-grid-group-collapse-rtl-iotify {
    padding-right: 0px;
    background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.jqx-grid-group-expand-iotify, .jqx-grid-group-expand-rtl-iotify {
    padding-right: 0px;
    background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.jqx-icon-arrow-first-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-first.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-last-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-last.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-first-hover-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-first.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-last-hover-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-last.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-first-selected-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-first.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-last-selected-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-last.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-listmenu-arrow-right-iotify {
    background-image: var(--jqx-list-arrow-right-color-iotify);
}

.jqx-listmenu-arrow-right-pressed-iotify {
    background-image: var(--jqx-list-arrow-right-selected-color-iotify);
}

.jqx-listmenu-arrow-left-iotify {
    background-image: var(--jqx-list-arrow-left-color-iotify);
}

.jqx-listmenu-arrow-left-pressed-iotify {
    background-image: var(--jqx-list-arrow-left-selected-color-iotify);
}

.jqx-pivotgrid-item .jqx-icon-arrow-up-iotify {
    background-image: var(--jqx-pivotgrid-arrow-up-color-iotify);
}
.jqx-pivotgrid-item .jqx-icon-arrow-down-iotify {
    background-image: var(--jqx-pivotgrid-arrow-down-color-iotify);
}
.jqx-pivotgrid-menu-button-iotify {
    background-image: var(--jqx-pivotgrid-menu-button-color-iotify);
}

.jqx-primary .jqx-icon-arrow-down-iotify, .jqx-warning .jqx-icon-arrow-down-iotify, .jqx-danger .jqx-icon-arrow-down-iotify, .jqx-success .jqx-icon-arrow-down-iotify, .jqx-info .jqx-icon-arrow-down-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png');
}

.jqx-primary .jqx-icon-arrow-down-selected-iotify, .jqx-warning .jqx-icon-arrow-down-selected-iotify, .jqx-danger .jqx-icon-arrow-down-selected-iotify, .jqx-success .jqx-icon-arrow-down-selected-iotify, .jqx-info .jqx-icon-arrow-down-selected-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png');
}

.jqx-primary .jqx-icon-arrow-down-hover-iotify, .jqx-warning .jqx-icon-arrow-down-hover-iotify, .jqx-danger .jqx-icon-arrow-down-hover-iotify, .jqx-success .jqx-icon-arrow-down-hover-iotify, .jqx-info .jqx-icon-arrow-down-hover-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png');
}

.jqx-primary .jqx-icon-arrow-up-iotify, .jqx-warning .jqx-icon-arrow-up-iotify, .jqx-danger .jqx-icon-arrow-up-iotify, .jqx-success .jqx-icon-arrow-up-iotify, .jqx-info .jqx-icon-arrow-up-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-up.png');
}

.jqx-primary .jqx-icon-arrow-up-selected-iotify, .jqx-warning .jqx-icon-arrow-up-selected-iotify, .jqx-danger .jqx-icon-arrow-up-selected-iotify, .jqx-success .jqx-icon-arrow-up-selected-iotify, .jqx-info .jqx-icon-arrow-up-selected-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-up.png');
}

.jqx-primary .jqx-icon-arrow-up-hover-iotify, .jqx-warning .jqx-icon-arrow-up-hover-iotify, .jqx-danger .jqx-icon-arrow-up-hover-iotify, .jqx-success .jqx-icon-arrow-up-hover-iotify, .jqx-info .jqx-icon-arrow-up-hover-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-up.png');
}


.jqx-primary .jqx-icon-arrow-left-iotify, .jqx-warning .jqx-icon-arrow-left-iotify, .jqx-danger .jqx-icon-arrow-left-iotify, .jqx-success .jqx-icon-arrow-left-iotify, .jqx-info .jqx-icon-arrow-left-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png');
}

.jqx-primary .jqx-icon-arrow-left-selected-iotify, .jqx-warning .jqx-icon-arrow-left-selected-iotify, .jqx-danger .jqx-icon-arrow-left-selected-iotify, .jqx-success .jqx-icon-arrow-left-selected-iotify, .jqx-info .jqx-icon-arrow-left-selected-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png');
}

.jqx-primary .jqx-icon-arrow-left-hover-iotify, .jqx-warning .jqx-icon-arrow-left-hover-iotify, .jqx-danger .jqx-icon-arrow-left-hover-iotify, .jqx-success .jqx-icon-arrow-left-hover-iotify, .jqx-info .jqx-icon-arrow-left-hover-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png');
}

.jqx-primary .jqx-icon-arrow-right-iotify, .jqx-warning .jqx-icon-arrow-right-iotify, .jqx-danger .jqx-icon-arrow-right-iotify, .jqx-success .jqx-icon-arrow-right-iotify, .jqx-info .jqx-icon-arrow-right-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-right.png');
}

.jqx-primary .jqx-icon-arrow-right-selected-iotify, .jqx-warning .jqx-icon-arrow-right-selected-iotify, .jqx-danger .jqx-icon-arrow-right-selected-iotify, .jqx-success .jqx-icon-arrow-right-selected-iotify, .jqx-info .jqx-icon-arrow-right-selected-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-right.png');
}

.jqx-primary .jqx-icon-arrow-right-hover-iotify, .jqx-warning .jqx-icon-arrow-right-hover-iotify, .jqx-danger .jqx-icon-arrow-right-hover-iotify, .jqx-success .jqx-icon-arrow-right-hover-iotify, .jqx-info .jqx-icon-arrow-right-hover-iotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-right.png');
}

.jqx-icon-arrow-down-hover-iotify {
    background-image: var(--jqx-arrow-down-hovered-color-iotify);
}

.jqx-icon-arrow-down-selected-iotify {
    background-image: var(--jqx-arrow-down-selected-color-iotify);
}

/* Ripple effect */
.ripple {
    position: relative;
    transform: translate3d(0, 0, 0);
    overflow:hidden;
}

.ink {
    display: block;
    position: absolute;
    pointer-events: none;
    border-radius: 0%;
    transform: scaleX(0);
    background: rgba(0,119,190,0.5); 
    opacity: 0.25;
}


.outlined .ink, .flat .ink {
    background: rgba(0,119,190,0.5); 
    overflow:hidden;
}

.ink.animate {
    animation: ripple .7s ease;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.jqx-checkbox-iotify .ripple,
.jqx-radiobutton-iotify .ripple
 {
    overflow:visible;
}
.jqx-checkbox-iotify .ink,
.jqx-radiobutton-iotify .ink 
 {
    transform: scale(0); 
    background: var(--jqx-accent-color-iotify);
    border-radius: 50%;
}
.jqx-checkbox-iotify .ink.animate,
.jqx-radiobutton-iotify .ink.animate
 {
    animation: checkRipple 0.3s ease;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.jqx-checkbox-iotify .ink.active,
.jqx-radiobutton-iotify .ink.active
 {
    opacity: 0.2;
    transform: scale(2);
}
.jqx-checkbox-default-iotify.active .ink,
.jqx-radiobutton-default-iotify.active .ink
 {
    opacity: 0.2;
    transform: scale(2);
}
/* Ripple effect */
.buttonRipple {
  background-position: center;
  transition: background 0.8s;
}
.jqx-widget-iotify.buttonRipple:hover {
  background: var(--jqx-button-default-hovered-state-background-color-iotify) radial-gradient(circle, transparent 1%, var(--jqx-button-default-hovered-state-background-color-iotify)  1%) center/15000%;
  border-color: var(--jqx-button-default-hovered-state-background-color-iotify);
}
.jqx-widget-iotify.buttonRipple:active {
  background-color: var(--jqx-button-default-ripple-color-iotify);
  background-size: 100%;
  transition: background 0s;
}
.jqx-widget-iotify.buttonRipple:active:not(:hover) {
      color: var(--jqx-background-color-iotify);
}
@keyframes ripple {
    100% {
        opacity: 0;
        transform: scale(5);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
}
@keyframes checkRipple {
    100% {
        opacity: 0.2;
        transform: scale(2);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
}

.jqx-fill-state-pressed-iotify .jqx-icon-delete-iotify
{
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-delete.png');
}
.jqx-fill-state-pressed-iotify .jqx-icon-edit-iotify
{
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-edit.png');
}
.jqx-fill-state-pressed-iotify .jqx-icon-save-iotify
{
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-save.png');
}
.jqx-fill-state-pressed-iotify .jqx-icon-cancel-iotify
{
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-cancel.png');
}
.jqx-fill-state-pressed-iotify .jqx-icon-search-iotify
{
    background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/search_white.png);
}
.jqx-fill-state-pressed-iotify .jqx-icon-plus-iotify
{
    background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/plus_white.png);
}
.jqx-fill-state-pressed-iotify .jqx-menu-minimized-button-iotify {
   background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-menu-minimized.png');
}
.jqx-fill-state-hover-iotify .jqx-editor-toolbar-icon-iotify,
.jqx-fill-state-pressed-iotify .jqx-editor-toolbar-icon-iotify {
    background: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/html_editor_white.png') no-repeat;
}

.jqx-fill-state-hover-iotify .jqx-editor-toolbar-icon-fontsize-iotify,
.jqx-fill-state-pressed-iotify .jqx-editor-toolbar-icon-fontsize-iotify,
.jqx-fill-state-hover-iotify .jqx-editor-toolbar-icon-forecolor-iotify,
.jqx-fill-state-pressed-iotify .jqx-editor-toolbar-icon-forecolor-iotify
{
        background: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/html_editor.png') no-repeat;
}

.jqx-editor-toolbar-button-iotify{
    border-color: #ddd;
    box-shadow: none !important;
	color: #333;
}

.jqx-widget-iotify #formWrap {
    color: #555 !important;    
}

.jqx-grid-cell-pinned-iotify{
    background-color:#e0e9f5 !important;
}
